import {Trans} from 'react-i18next';
import React from 'react';
import styled from 'styled-components';

import {Application} from '../../types/application';
import Button, {ButtonWrap} from '../../components/Button';
import Content from '../../components/Content';

interface Props {
    installedApps: Application[];
    scheduledApps: Application[];
    onChangeApps: () => void;
    onContinue: () => void;
}

const AppsSummary: React.FC<{apps?: Application[]}> = ({apps}) => {
    if (!apps) {
        return (
            <p className="muted">
                <Trans i18nKey="contentScheduled.loadingApps">Loading apps</Trans>
            </p>
        );
    }

    if (!apps.length) {
        return (
            <p className="muted">
                <Trans i18nKey="contentScheduled.noApps">No apps found</Trans>
            </p>
        );
    }

    return (
        <ul>
            {apps.map(app => (
                <li key={app.id}><AppIcon src={app.icon} /> <span>{app.name}</span></li>
            ))}
        </ul>
    );
};

const ContentScheduled: React.FC<Props> = ({
    installedApps,
    scheduledApps,
    onChangeApps,
    onContinue,
}) => {
    return (
        <Wrap data-testid="content-scheduled">
            <ScrollableContent>
                <h1>
                    <Trans i18nKey="contentScheduled.title">Content already scheduled</Trans>
                </h1>

                <h2>
                    <Trans i18nKey="contentScheduled.installedAppsTitle">Installed Apps</Trans>
                </h2>
                <AppsSummary apps={installedApps} />

                <h2>
                    <Trans i18nKey="contentScheduled.scheduledAppsTitle">Scheduled Apps</Trans>
                </h2>
                <AppsSummary apps={scheduledApps} />

                <Buttons>
                    <Button
                        background="var(--secondary)"
                        color="var(--foreground)"
                        onClick={onChangeApps}
                        testId="change-apps-button"
                    >
                        <Trans i18nKey="contentScheduled.changeAppsButton">Change</Trans>
                    </Button>
                    <Button testId="continue-button" onClick={onContinue}>
                        <Trans i18nKey="contentScheduled.continueButton">Continue</Trans>
                    </Button>
                </Buttons>
            </ScrollableContent>
        </Wrap>
    );
};

export default ContentScheduled;

const AppIcon = styled.img`
    border-radius: 1rem;
    height: 2rem;
    width: 2rem;
    margin-right: 0.75rem;
`;

const Buttons = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    width: 100%;

    ${ButtonWrap} + & {
        margin-top: 1rem;
    }

    ${ButtonWrap} {
        min-width: 0;

        + ${ButtonWrap} {
            margin-left: 1rem;
        }
    }
`;

const ScrollableContent = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    justify-content: center;
`;

const Wrap = styled(Content)`
    justify-content: flex-start;

    ${ButtonWrap} {
        margin-top: 1rem;
    }

    .muted {
        opacity: 0.5;
    }

    h1 {
        margin-bottom: 0;
    }

    h2 {
        margin: 2rem 0 1rem;
    }

    p {
        margin: 0;
        padding: 0;
    }

    small {
        text-transform: none;
        font-size: 0.75em;
        margin-top: 0.5rem;
    }

    li,
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 0 0 0.5rem;
        text-align: left;
    }
`;
