import {Trans, useTranslation} from 'react-i18next';
import React, {useState} from 'react';
import styled from 'styled-components';

import {useAuth} from '../../services/auth';
import Button, {ButtonWrap} from '../../components/Button';
import Content from '../../components/Content';
import Field, {FieldWrap} from '../../components/Field';
import Input from '../../components/Input';
import useInputValue from '../../hooks/useInputValue';

interface Props {
    onLogIn: () => void;
}

const preventDefault = (callback: () => void) => (event: React.SyntheticEvent<HTMLElement>) => {
    event.preventDefault();
    callback();
};

const LogIn: React.FC<Props> = ({onLogIn}) => {
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const email = useInputValue('');
    const password = useInputValue('');
    const {logIn} = useAuth();

    const onAttemptLogin = async () => {
        try {
            setLoading(true);
            await logIn(email.value, password.value);
            onLogIn();
        } catch (e) {
            setLoading(false);
            setError(e.message);
        }
    };

    const {t} = useTranslation();

    return (
        <Wrap data-testid="log-in">
            <h1>
                <Trans i18nKey="logIn.title">
                    <small>Step 1:</small> Log in to your meld account
                </Trans>
            </h1>
            {loading && (
                <Notice data-testid="log-in-loading">
                    <Trans i18nKey="logIn.loading">Loading...</Trans>
                </Notice>
            )}
            {!loading && !!error && <Notice data-testid="log-in-error">{error}</Notice>}

            <Form onSubmit={preventDefault(onAttemptLogin)}>
                <Field placeholder={t('logIn.emailFieldLabel')}>
                    <Input
                        autoComplete="email"
                        data-testid="log-in-email"
                        type="email"
                        {...email}
                    />
                </Field>
                <Field placeholder={t('logIn.passwordFieldLabel')}>
                    <Input
                        data-testid="log-in-password"
                        autoComplete="current-password"
                        type="password"
                        {...password}
                    />
                </Field>
                <Button testId="log-in-button" onClick={onAttemptLogin}>
                    <Trans i18nKey="logIn.logInButton">Log In</Trans>
                </Button>
            </Form>
        </Wrap>
    );
};

export default LogIn;

const Form = styled.form`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Notice = styled.p`
    color: var(--warning);
`;

const Wrap = styled(Content)`
    h1 {
        margin-bottom: 1.5rem;
    }

    ${FieldWrap} {
        margin: 0 0 0.5rem 0;
    }

    ${ButtonWrap} {
        margin-top: 1.5rem;
    }
`;
