import {Version} from './version';

export enum ApplicationType {
    ANDROID = 'android',
    PACKAGED = 'packaged',
    URL = 'url',
}

export interface Application {
    id: number;
    name: string;
    icon: string;
    type: ApplicationType;
    versions?: Version[];
}

export interface ApplicationSummary {
    id: number;
    application?: Application;
}

export interface DraftApplication {
    name: string;
    type: ApplicationType;
}
