import {HamburgerSliderReverse} from 'react-animated-burgers';
import {useHistory} from 'react-router-dom';
import HelpCircleOutlineIcon from 'mdi-react/HelpCircleOutlineIcon';
import LogoutVariantIcon from 'mdi-react/LogoutVariantIcon';
import React, {useState} from 'react';
import TicketConfirmationOutlineIcon from 'mdi-react/TicketConfirmationOutlineIcon';
import classnames from 'classnames';
import styled, {css} from 'styled-components';

import {useAuth} from '../../services/auth';

const FAQ_URL = 'https://meldcx.zendesk.com/hc/en-us/categories/360000128536-meldCanvas';

export default () => {
    const [open, setOpen] = useState(false);
    const {logOut, user} = useAuth();
    const history = useHistory();

    const onLogOut = () => {
        logOut();
        history.push('/');
    };

    return (
        <Wrap className={classnames({active: open})} data-testid="menu">
            <HamburgerSliderReverse
                barColor="currentColor"
                buttonWidth={20}
                data-testid={open ? 'menu-close' : 'menu-open'}
                isActive={open}
                toggleButton={() => setOpen(!open)}
            />

            <Sidebar>
                <Divider />
                <Profile>
                    {!!user.icon && <Avatar src={user.icon} />}
                    {!user.icon && <AvatarPlaceholder />}
                    <Detail>
                        <h3>
                            {user.firstName} {user.lastName}
                        </h3>
                        <p>{user.email}</p>
                    </Detail>
                </Profile>
                <Divider />
                <ItemButton data-testid="menu-logout" onClick={onLogOut}>
                    <LogoutVariantIcon /> Log Out
                </ItemButton>
                <Divider />
                <h4>Support</h4>
                {Boolean(process.env.LOG_SUPPORT_TICKET_URL) && (
                    <ItemLink
                        target="_blank"
                        rel="noopener"
                        data-testid="menu-support-link"
                        href={process.env.LOG_SUPPORT_TICKET_URL}
                    >
                        <TicketConfirmationOutlineIcon /> Log Ticket
                    </ItemLink>
                )}
                <ItemLink target="_blank" rel="noopener" data-testid="menu-faq" href={FAQ_URL}>
                    <HelpCircleOutlineIcon /> FAQ
                </ItemLink>
            </Sidebar>

            <Shade onClick={() => setOpen(false)} />
        </Wrap>
    );
};

const Avatar = styled.img`
    background: var(--tint-2);
    border-radius: 100%;
    height: 3rem;
    width: 3rem;
    flex: 0 0 auto;
    margin-right: 1rem;
`;

const AvatarPlaceholder = styled.span`
    background: var(--tint-2);
    border-radius: 100%;
    height: 3rem;
    width: 3rem;
    flex: 0 0 auto;
    margin-right: 1rem;
`;

const Detail = styled.div`
    flex: 1 0;
    text-align: left;

    h3 {
        font-size: 1rem;
        line-height: 1.15;
        margin: 0 1rem 0.125rem 0;
    }

    p {
        font-size: 0.8125rem;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const Divider = styled.hr`
    background: var(--tint-2);
    border: 0;
    height: 1px;
    margin: 0.75rem 0;
    opacity: 1;
`;

const Item = css`
    align-items: center;
    background: rgba(0, 0, 0, 0);
    cursor: pointer;
    display: flex;
    font-size: 0.8125rem;
    margin: 0 -1.25rem;
    padding: 0.75rem 1.5rem;
    transition: 75ms background-color;

    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }

    svg {
        margin-right: 1rem;
    }
`;

const ItemButton = styled.div`
    ${Item}
`;

const ItemLink = styled.a`
    ${Item}
`;

const Profile = styled.div`
    align-items: center;
    display: flex;
    margin: 0 -1.25rem;
    padding: 1rem 1.25rem;
`;

const Shade = styled.div`
    background: #000000;
    opacity: 0;
    content: '';
    height: 100%;
    left: -100%;
    top: 0;
    position: fixed;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition: 150ms opacity;
    width: 100%;
`;

const Sidebar = styled.div`
    background: var(--foreground);
    box-sizing: border-box;
    height: 100vh;
    left: -100%;
    max-width: 20rem;
    opacity: 0;
    padding: 3.25rem 1.25rem;
    position: fixed;
    top: 0;
    transform: translateX(-1rem);
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition: 150ms opacity, 150ms transform;
    width: calc(100% - 1rem);

    h4 {
        font-size: 0.875rem;
        text-transform: uppercase;
        text-align: left;
        margin: 1.5rem 0.25rem 0.5rem;
    }
`;

const Wrap = styled.div`
    &.active {
        color: var(--shade-2);

        ${Shade} {
            opacity: 0.6;
            z-index: 10;
            left: 0;
        }

        ${Sidebar} {
            transform: translateX(0);
            z-index: 11;
            opacity: 1;
            left: 0;
        }
    }

    button {
        height: 4rem;
        min-width: 0;
        padding: 0;
        width: 4rem;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 12;

        &:after {
            content: none;
        }

        span {
            &,
            :before,
            :after {
                height: 0.0625rem;
            }
        }
    }
`;
