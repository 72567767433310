import {from} from 'rxjs';
import React, {useEffect, useState} from 'react';

import {DeviceApps} from '../../hooks/useMeldAPI';
import {useBluetooth} from '../../services/bluetooth';
import {useMeld} from '../../services/meld';
import CheckForContent from '../CheckForContent';
import ContentScheduled from '../ContentScheduled';
import SelectContent from '../SelectContent';

interface Props {
    onChangeApps: () => void;
    onNext: (error?: string) => void;
    onSkip: () => void;
}

const ManageContent: React.FC<Props> = ({onChangeApps, onNext, onSkip}) => {
    const {identifiers} = useBluetooth();
    const {getDeviceApps} = useMeld();
    const [deviceApps, setDeviceApps] = useState<DeviceApps>();

    useEffect(() => {
        if (identifiers?.deviceId) {
            const deviceApps$ = from(getDeviceApps(identifiers.deviceId));
            const subscription = deviceApps$.subscribe(deviceApps => setDeviceApps(deviceApps));
            return () => subscription.unsubscribe();
        }
    }, [identifiers?.deviceId]);

    if (!deviceApps) return <CheckForContent />;
    if (deviceApps?.scheduled.length > 0)
        return (
            <ContentScheduled
                installedApps={deviceApps.installed}
                onChangeApps={onChangeApps}
                onContinue={onSkip}
                scheduledApps={deviceApps.scheduled}
            />
        );

    return <SelectContent onNext={onNext} onSkip={onSkip} />;
};

export default ManageContent;
