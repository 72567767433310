import { useCallback, useState } from 'react';

export default <T>(initialValue: T) => {
    const [value, setValue] = useState<T>(initialValue);
    const onChange = useCallback((event: any) => {
        setValue(event.target.value);
    }, []);

    return { value, onChange };
};
