import {useCallback, useMemo} from 'react';

import {Account} from '../types/account';
import {Application, ApplicationType} from '../types/application';
import {MeldDevice as Device} from '../types/device';
import {DeviceContent} from '../types/content';
import {LanguageSpec} from '../types/language';
import {useAuth} from '../services/auth';
import * as languageHelper from '../helpers/language';
import * as meldHelper from '../helpers/meld';

export interface UnauthedMeldAPI {
    getLanguageSpec: (language: string, scope: string) => Promise<LanguageSpec>;
}

export interface DeviceApps {
    installed: Application[];
    scheduled: Application[];
}

export interface AuthedMeldAPI {
    authenticate: (challenge: string, deviceId: number) => Promise<string>;
    enrolDeviceAndSwitchAccount: (serial: string, accountId: number) => Promise<void>;
    getAccounts: () => Promise<Account[]>;
    getApplications: () => Promise<Application[]>;
    getApplicationsByType: (types: ApplicationType[]) => Promise<Application[]>;
    getDeviceApps: (deviceId: number) => Promise<DeviceApps>;
    getLanguageSpec: (language: string) => Promise<LanguageSpec>;
    loadContent: (deviceId: number, content: DeviceContent) => void;
}

export type MeldAPI = UnauthedMeldAPI & AuthedMeldAPI;

interface Props {
    apiUrl: string;
}

export default ({apiUrl}: Props): MeldAPI => {
    const {switchToAccount, token} = useAuth();

    const throwNotAuthenticated = useCallback(() => {
        throw new Error('Not authenticated with meld');
    }, []);

    const enrolDeviceAndSwitchAccount = useCallback(
        async (serial: string, accountId: number) => {
            if (!token) throwNotAuthenticated();
            const {token: newToken} = await switchToAccount(accountId);
            return meldHelper.enrolDevice(apiUrl, newToken, serial);
        },
        [apiUrl, token]
    );

    const useAuthenticatedCallback = <Arguments extends any[], Result>(
        callback: (this: null, apiUrl: string, token: string, ...args: Arguments) => Result
    ): ((...args: Arguments) => Result) =>
            useMemo(() => (token ? callback.bind(null, apiUrl, token) : throwNotAuthenticated), [
                apiUrl,
                token,
            ]);

    const getLanguageSpec = useCallback(
        languageHelper.getLanguageSpec.bind(languageHelper, apiUrl),
        [languageHelper.getLanguageSpec]
    );

    const getDeviceApps = useAuthenticatedCallback(
        (apiUrl: string, token: string, deviceId: number) =>
            meldHelper
                .getDevice(apiUrl, token, deviceId, {detailed: true})
                .then((device: Device) => {
                    const installed = device.applications || [];
                    const scheduled =
                        device.displays?.flatMap(display => display.applications.map(application => ({
                            ...application.application,
                            icon: installed.find(i => i.id === application.application.id).icon || '',
                        })) ?? []) ?? [];

                    return {
                        installed,
                        scheduled,
                    };
                })
    );

    const authenticate = useAuthenticatedCallback(meldHelper.authenticate);
    const getAccounts = useAuthenticatedCallback(meldHelper.getAccounts);
    const getApplications = useAuthenticatedCallback(meldHelper.getApplications);
    const getApplicationsByType = useAuthenticatedCallback(meldHelper.getApplicationsByType);
    const loadContent = useAuthenticatedCallback(meldHelper.loadDeviceContent);

    const meldAPI: MeldAPI = useMemo(
        () => ({
            authenticate,
            enrolDeviceAndSwitchAccount,
            getAccounts,
            getApplications,
            getApplicationsByType,
            getDeviceApps,
            getLanguageSpec,
            loadContent,
        }),
        [
            authenticate,
            enrolDeviceAndSwitchAccount,
            getAccounts,
            getApplications,
            getApplicationsByType,
            getDeviceApps,
            getLanguageSpec,
            loadContent,
        ]
    );

    return meldAPI;
};
