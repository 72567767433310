import { Trans } from 'react-i18next';
import React, { useState } from 'react';

import Button from '../../components/Button';
import Popup from '../../components/Popup';

interface Props {
    onClose: () => void;
}

const SessionExpiredPopup: React.FC<Props> = ({ onClose }) => {
    const [close, setClose] = useState(false);
    return (
        <Popup
            testId="session-expired-popup"
            onClose={onClose}
            shouldClose={close}
        >
            <h2>
                <Trans i18nKey="popup.sessionExpired.title">
                    Session timed out
                </Trans>
            </h2>
            <p>
                <Trans i18nKey="popup.sessionExpired.instruction">
                    Sorry, your session timed out after an extended period of
                    inactivity. Please sign in again to continue.
                </Trans>
            </p>
            <Button
                background="var(--tertiary)"
                color="var(--foreground)"
                testId="session-expired-close"
                onClick={() => setClose(true)}
            >
                <Trans i18nKey="popup.sessionExpired.closeButton">Close</Trans>
            </Button>
        </Popup>
    );
};

export default SessionExpiredPopup;
