import {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {BluetoothInterface} from '../types/bluetooth';
import {Translation} from '../types/language';
import {
    authenticateDevice,
    confirmProtocolVersion,
    getBluetoothAvailability,
    getMeldDevice,
} from '../helpers/bluetooth';
import {getInterface as getBluetoothInterface} from '../helpers/bluetoothInterface';
import {WEB_LANGUAGE_NAMESPACE} from '../helpers/language';
import {useMeld} from '../services/meld';
import * as bluetoothRequests from '../helpers/bluetoothRequests';

const SUPPORTED_BLUETOOTH_PROTOCOL_VERSIONS = [1];

export interface BluetoothAPI {
    isAvailable: boolean;
    getDevice: () => Promise<{device: BluetoothDevice; bluetoothInterface: BluetoothInterface}>;
    pairingStatus: Translation | undefined | null;
}

export default () => {
    const [isAvailable, setIsAvailable] = useState(false);
    const [pairingStatus, setPairingStatus] = useState<Translation | undefined | null>();
    const {authenticate, getLanguageSpec} = useMeld();
    const {i18n} = useTranslation();

    useEffect(() => {
        const subscription = getBluetoothAvailability().subscribe(setIsAvailable);
        return () => subscription.unsubscribe();
    }, []);

    const assertIsAvailable = useCallback(() => {
        if (!isAvailable) {
            throw new Error('Bluetooth is not available');
        }
    }, [isAvailable]);

    const getDevice = useCallback(async () => {
        assertIsAvailable();
        setPairingStatus({key: 'pairingStatus.device', fallback: 'Connecting to device'});
        const device = await getMeldDevice();
        const bluetoothInterface = await getBluetoothInterface(device);

        setPairingStatus({key: 'pairingStatus.protocol', fallback: 'Checking protocol'});
        await confirmProtocolVersion(bluetoothInterface, SUPPORTED_BLUETOOTH_PROTOCOL_VERSIONS);

        setPairingStatus({key: 'pairingStatus.authentication', fallback: 'Authenticating'});
        await authenticateDevice(bluetoothInterface, authenticate);

        setPairingStatus({key: 'pairingStatus.timezone', fallback: 'Setting timezone'});
        await bluetoothRequests.setTimezone(
            bluetoothInterface,
            Intl.DateTimeFormat().resolvedOptions().timeZone
        );
        setPairingStatus({key: 'pairingStatus.language', fallback: 'Setting language'});
        for (const language of i18n.languages) {
            /**
             * Change from onboarding-android -> enrolment-web
             * 
             * Using onboarding-android causing 404 issue in the API request
             */
            const languageSpec = await getLanguageSpec(language, WEB_LANGUAGE_NAMESPACE); 

            if (!languageSpec) {
                continue;
            }

            await bluetoothRequests.setLanguage(bluetoothInterface, languageSpec);
            break;
        }

        return {device, bluetoothInterface};
    }, [assertIsAvailable, setPairingStatus, authenticate]);

    const api: BluetoothAPI = useMemo(
        () => ({
            isAvailable,
            getDevice,
            pairingStatus,
        }),
        [isAvailable, getDevice, pairingStatus]
    );

    return api;
};
