import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

interface Props {
    children?: React.ReactNode;
    placeholder?: string;
    size?: 'normal' | 'huge';
    style?: React.CSSProperties;
}

const Field: React.FC<Props> = ({ children, placeholder, size, style }) => (
    <Wrap style={style} className={classnames(size)}>
        {children}
        <Placeholder>{placeholder}</Placeholder>
    </Wrap>
);

export default Field;

const Placeholder = styled.label`
    left: 1rem;
    pointer-events: none;
    position: absolute;
    top: 0.875rem;
    transform-origin: 0 0;
    transition: 75ms transform, 75ms opacity;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: ease;
`;

const Wrap = styled.div`
    --active-label-scale: 0.65;
    --active-label-translation: -60%;
    
    color: var(--input-foreground);
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 21rem;
    position: relative;
    width: 100%;

    input,
    select,
    textarea {
        padding-top: 0.625rem;
        padding-bottom: 0;
        width: 100%;
        :focus + label,
        :not(:placeholder-shown) + label {
            opacity: 0.75;
            transform: scale(var(--active-label-scale)) translateY(var(--active-label-translation)) translateZ(0);
        }
        :focus + label {
            opacity: 0.5;
        }
    }

    textarea {
        padding-top: 1rem;
    }

    select.empty + label {
        transform: none;
    }
`;

export const FieldWrap = Wrap;
