import {Trans} from 'react-i18next';
import React, {useState} from 'react';
import styled from 'styled-components';

import {useBluetooth} from '../../services/bluetooth';
import Button from '../../components/Button';
import Popup from '../../components/Popup';

interface Props {
    onClose: () => void;
}

const ChangeAppsPopup: React.FC<Props> = ({onClose}) => {
    const {identifiers} = useBluetooth();
    const [close, setClose] = useState(false);
    return (
        <Popup testId="change-apps-popup" onClose={onClose} shouldClose={close}>
            <h2>
                <Trans i18nKey="popup.changeApps.title">Change installed apps</Trans>
            </h2>
            {Boolean(identifiers?.callHomeId) && (
                <Identifier data-testid="device-removal-callhome">
                    <strong>
                        <Trans i18nKey="popup.deviceRemoval.callhomeId">Callhome ID</Trans>
                    </strong>{' '}
                    {identifiers.callHomeId}
                </Identifier>
            )}
            {Boolean(identifiers?.serial) && (
                <Identifier data-testid="device-removal-serial">
                    <strong>
                        <Trans i18nKey="popup.deviceRemoval.serial">Serial</Trans>
                    </strong>{' '}
                    {identifiers.serial}
                </Identifier>
            )}
            <p>
                <Trans i18nKey="popup.changeApps.instruction">
                    To change which apps are installed and scheduled on this device, log in to your
                    meld account at <a href={process.env.MELD_URL}>{process.env.MELD_URL}</a>,
                    switch to the relevant account, head to <strong>Devices</strong>, locate the
                    device and open the <strong>Apps</strong> tab.
                </Trans>
            </p>
            <Button
                background="var(--tertiary)"
                color="var(--foreground)"
                onClick={() => setClose(true)}
                testId="change-apps-popup-close"
            >
                <Trans i18nKey="popup.changeApps.closeButton">Dismiss</Trans>
            </Button>
        </Popup>
    );
};

export default ChangeAppsPopup;

const Identifier = styled.p`
    margin: 0 0 0.5rem;
`;
