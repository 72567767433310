import { useEffect, useRef } from 'react';
import Rollbar from 'rollbar';

import { useAuth } from '../services/auth';

interface MonitoringAPI {
    error: (message: any) => void;
    info: (message: any) => void;
}

interface Props {
    enabled?: boolean;
    token?: string;
}

export default ({ enabled, token }: Props): MonitoringAPI => {
    const { user } = useAuth();
    const rollbar = useRef<Rollbar>();

    useEffect(() => {
        rollbar.current = new Rollbar({
            accessToken: token,
            captureUncaught: true,
            captureUnhandledRejections: true,
            enabled,
            payload: {
                environment: process.env.NODE_ENV || 'development',
            },
        });
    }, []);

    // Listen for user
    useEffect(() => {
        if (rollbar.current) {
            rollbar.current.configure({
                payload: {
                    person: user,
                },
            });
        }
    }, [user]);

    return {
        error: (message: any) => rollbar.current.error(message),
        info: (message: any) => rollbar.current.info(message),
    };
};
