import {Redirect, Route, Switch, useHistory} from 'react-router-dom';
import {distinctUntilChanged, map, skipWhile} from 'rxjs/operators';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

import {DeviceConnectivity, WifiNetwork} from '../../types/network';
import {useAuth} from '../../services/auth';
import {useBluetooth} from '../../services/bluetooth';
import AlreadyEnrolled from '../AlreadyEnrolled';
import BaseStyle from '../../styles/BaseStyle';
import BluetoothLostPopup from '../BluetoothLostPopup';
import ChangeAppsPopup from '../ChangeAppsPopup';
import Completed from '../Completed';
import ConnectToNetwork from '../ConnectToNetwork';
import ConnectedToNetwork from '../ConnectedToNetwork';
import DeviceRemovalPopup from '../DeviceRemovalPopup';
import Enrol from '../Enrol';
import Footer from '../../components/Footer';
import LogIn from '../LogIn';
import LostInternetPopup from '../LostInternetPopup';
import ManageContent from '../ManageContent';
import Menu from '../Menu';
import Pair from '../Pair';
import Paired from '../Paired';
import SelectNetwork from '../SelectNetwork';
import SessionExpiredPopup from '../SessionExpiredPopup';
import Unsupported from '../Unsupported';
import Welcome from '../Welcome';

enum Notice {
    CHANGE_APPS_REQUEST,
    DEVICE_REMOVAL_REQUEST,
    BLUETOOTH_LOST,
    LOST_INTERNET,
    SESSION_EXPIRED,
}

const App: React.FC = () => {
    const history = useHistory();
    const {
        closeDevice,
        device,
        deviceConnectivity$,
        deviceDisconnection$,
        identifiers,
        isAvailable,
        network,
        reset,
    } = useBluetooth();
    const {expiredSession$, user} = useAuth();
    const [selectedNetwork, setSelectedNetwork] = useState<WifiNetwork>();
    const [contentWarning, setContentWarning] = useState<string>();
    const [notice, setNotice] = useState<Notice>();

    useEffect(() => {
        reset();
    }, []);

    useEffect(() => {
        if (expiredSession$) {
            const subscription = expiredSession$.subscribe(() => setNotice(Notice.SESSION_EXPIRED));
            return () => subscription.unsubscribe();
        }
    }, [expiredSession$]);

    useEffect(() => {
        if (deviceConnectivity$) {
            const subscription = deviceConnectivity$
                .pipe(
                    skipWhile(({connectsToMeld}) => !connectsToMeld),
                    map(({connectsToMeld}: DeviceConnectivity) =>
                        connectsToMeld === false ? Notice.LOST_INTERNET : undefined
                    ),
                    distinctUntilChanged()
                )
                .subscribe((notice: Notice) => setNotice(notice));
            return () => subscription.unsubscribe();
        }
    }, [deviceConnectivity$, setNotice]);

    useEffect(() => {
        if (device && deviceDisconnection$) {
            const subscription = deviceDisconnection$.subscribe(() =>
                setNotice(Notice.BLUETOOTH_LOST)
            );
            return () => subscription.unsubscribe();
        }
    }, [device, deviceDisconnection$, setNotice]);

    useEffect(() => {
        if (window.Cypress) {
            window.router = {history};
        }
    }, []);

    useEffect(() => {
        if (selectedNetwork?.isCurrentlyConnected) {
            history.push('/connected-to-network');
        } else {
            history.push('/connect-to-network');
        }
    }, [selectedNetwork]);

    const handleReset = () => {
        reset();
        setNotice(undefined);
        closeDevice();
        history.push('/');
    };

    return (
        <Wrap>
            <BaseStyle />

            {notice === Notice.LOST_INTERNET && (
                <LostInternetPopup onClose={() => handleReset()} />
            )}

            {notice === Notice.BLUETOOTH_LOST && (
                <BluetoothLostPopup onClose={() => handleReset()} />
            )}

            {notice === Notice.SESSION_EXPIRED && (
                <SessionExpiredPopup onClose={() => setNotice(undefined)} />
            )}

            {notice === Notice.DEVICE_REMOVAL_REQUEST && (
                <DeviceRemovalPopup onClose={() => setNotice(undefined)} />
            )}

            {notice === Notice.CHANGE_APPS_REQUEST && (
                <ChangeAppsPopup onClose={() => setNotice(undefined)} />
            )}

            {isAvailable && Boolean(user) && <Menu />}

            {isAvailable && (
                <Switch>
                    <Route path="/" exact>
                        <Welcome onNext={() => history.push(user ? '/pair' : '/login')} />
                    </Route>
                    {!user && (
                        <Route path="/login" exact>
                            <LogIn onLogIn={() => history.push('/pair')} />
                        </Route>
                    )}

                    {Boolean(user) && (
                        <Route path="/pair" exact>
                            <Pair onPaired={() => history.push('/paired')} />
                        </Route>
                    )}

                    {Boolean(device) && (
                        <Route path="/paired" exact>
                            <Paired onNext={() => history.push('/select-network')} />
                        </Route>
                    )}

                    {Boolean(device) && (
                        <Route path="/select-network" exact>
                            <SelectNetwork
                                onSelect={(network: WifiNetwork) =>
                                    setSelectedNetwork({...network})
                                }
                            />
                        </Route>
                    )}

                    {Boolean(device && selectedNetwork) && (
                        <Route path="/connect-to-network" exact>
                            <ConnectToNetwork
                                network={selectedNetwork}
                                onConnected={() => history.push('/connected-to-network')}
                                onCancel={() => history.push('/select-network')}
                            />
                        </Route>
                    )}

                    {Boolean(device && network) && (
                        <Route path="/connected-to-network" exact>
                            <ConnectedToNetwork
                                onEnrolled={() => history.push('/already-enrolled')}
                                onUnenrolled={() =>history.push('/enrol')}
                            />
                        </Route>
                    )}

                    {Boolean(identifiers) && (
                        <Route path="/enrol" exact>
                            <Enrol onEnrolled={() => history.push('/manage-content')} />
                        </Route>
                    )}

                    {Boolean(identifiers) && (
                        <Route path="/already-enrolled" exact>
                            <AlreadyEnrolled
                                onCancel={handleReset}
                                onNext={() => history.push('/manage-content')}
                                onRemove={() => setNotice(Notice.DEVICE_REMOVAL_REQUEST)}
                            />
                        </Route>
                    )}

                    {Boolean(identifiers) && (
                        <Route path="/manage-content" exact>
                            <ManageContent
                                onChangeApps={() => setNotice(Notice.CHANGE_APPS_REQUEST)}
                                onNext={(warning: string) => {
                                    setContentWarning(warning);
                                    history.push('/completed');
                                    closeDevice();
                                }}
                                onSkip={() => {
                                    history.push('/completed');
                                    closeDevice();
                                }}
                            />
                        </Route>
                    )}

                    {Boolean(user) && (
                        <Route path="/completed" exact>
                            <Completed
                                warning={contentWarning}
                                onSetupNewDevice={() => history.push('/pair')}
                            />
                        </Route>
                    )}

                    <Redirect to="/" />
                </Switch>
            )}
            {!isAvailable && <Unsupported />}
            <Footer />
        </Wrap>
    );
};

export default App;

const Wrap = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    padding-bottom: 2rem;
    text-align: center;
    width: 100vw;
`;
