import {Trans, useTranslation} from 'react-i18next';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

import {WifiNetwork, WifiStatus} from '../../types/network';
import {useBluetooth} from '../../services/bluetooth';
import {useReplay} from '../../services/replay';
import Button, {ButtonWrap} from '../../components/Button';
import Content from '../../components/Content';
import Field, {FieldWrap} from '../../components/Field';
import Input from '../../components/Input';
import helmet from '../SelectNetwork/helmet.svg';
import useInputValue from '../../hooks/useInputValue';

interface Props {
    network: WifiNetwork;
    onCancel: () => void;
    onConnected: () => void;
}

const ConnectToNetwork: React.FC<Props> = ({network, onCancel, onConnected}) => {
    const {rememberNetwork, rememberPassphrase} = useReplay();
    const {cancelJoin, joinWifiNetwork, joinStatus} = useBluetooth();
    const password = useInputValue('');
    const {t} = useTranslation();
    const [failed, setFailed] = useState(false);

    useEffect(() => {
        return () => {
            cancelJoin();
        };
    }, []);

    const onConnect = async () => {
        try {
            setFailed(false);
            const connectedNetwork = await joinWifiNetwork(network, password.value);
            rememberNetwork(connectedNetwork);
            rememberPassphrase(password.value);
            onConnected();
        } catch {
            setFailed(true);
        }
    };

    return (
        <Wrap data-testid="connect-to-network">
            <img src={helmet} />
            <h1>
                <Trans i18nKey="connectToNetwork.title" name={network.ssid}>
                    Connect to <small>&lsquo;{network.ssid}&rsquo;</small>
                </Trans>
            </h1>
            {joinStatus === WifiStatus.CONNECTED && (
                <Notice data-testid="status-connected">
                    <Trans i18nKey="connectToNetwork.connectedNotice">Connected!</Trans>
                </Notice>
            )}
            {joinStatus === WifiStatus.CONNECTING && (
                <Notice data-testid="status-connecting">
                    <Trans i18nKey="connectToNetwork.connectingNotice">Connecting...</Trans>
                </Notice>
            )}
            {failed && [WifiStatus.DISCONNECTED, WifiStatus.UNAUTHENTICATED].includes(joinStatus) && (
                <Notice data-testid="status-failed">
                    <Trans i18nKey="connectToNetwork.failedNotice">
                        Unable to connect - check credentials and try again
                    </Trans>
                </Notice>
            )}

            <Field placeholder={t('connectToNetwork.passwordFieldLabel')}>
                <Input
                    data-testid="password"
                    disabled={joinStatus === WifiStatus.CONNECTING}
                    readOnly={joinStatus === WifiStatus.CONNECTING}
                    type="password"
                    {...password}
                />
            </Field>
            <Buttons>
                <Button
                    background="var(--secondary)"
                    color="var(--foreground)"
                    onClick={onCancel}
                    testId="cancel"
                >
                    <Trans i18nKey="connectToNetwork.cancelButton">Cancel</Trans>
                </Button>
                <Button
                    testId="connect"
                    onClick={
                        joinStatus !== WifiStatus.CONNECTING
                            ? onConnect
                            : undefined
                    }
                >
                    <Trans i18nKey="connectToNetwork.connectButton">Connect</Trans>
                </Button>
            </Buttons>
        </Wrap>
    );
};

export default ConnectToNetwork;

const Buttons = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;

    ${ButtonWrap} {
        min-width: 0;

        + ${ButtonWrap} {
            margin-left: 1rem;
        }
    }
`;

const Notice = styled.p`
    color: var(--success);
    margin: 0;
`;

const Wrap = styled(Content)`
    flex: 1 0 auto;

    h1 {
        margin-bottom: 0;
        text-align: center;

        small {
            text-transform: none;
            font-size: 0.75em;
            margin-top: 0.25rem;
        }
    }

    input[disabled] {
        opacity: 0.5;
    }

    img {
        flex: 0 0 auto;
        margin: 0 0.5rem 1rem;
        height: auto;
        width: 8rem;
    }

    ${FieldWrap} {
        margin: 1rem 0 1.5rem;
    }
`;
