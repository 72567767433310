import {Observable, Subject} from 'rxjs';
import {useEffect, useMemo, useRef, useState} from 'react';
import store from 'store';

import {User} from '../types/user';
import {getUser, logIn, refreshToken, switchToAccount, validateToken} from '../helpers/meld';

const TOKEN_REFRESH_THRESHOLD = 5 * 60 * 1000;

export interface AuthAPI extends Partial<AuthState> {
    expiredSession$: Observable<void>;
    logIn: (email: string, password: string) => Promise<void>;
    logOut: () => void;
    switchToAccount?: (accountId: number) => Promise<AuthState>;
}

export interface AuthState {
    accountId: number;
    expiresAt: number;
    token: string;
    user: User;
}

interface Props {
    apiUrl: string;
}

export default ({apiUrl}: Props) => {
    const [authState, setAuthState] = useState<AuthState | undefined>();
    const refreshTimeout = useRef<number>();
    const [ready, setReady] = useState(false);

    const expiredSession$ = useMemo(() => new Subject<void>(), []);

    useEffect(() => {
        return () => expiredSession$.complete();
    }, []);

    const refresh = async () => {
        if (!authState) {
            return;
        }

        const {expiresAt, token} = await refreshToken(apiUrl, authState.token);
        const newAuthState: AuthState = {
            ...authState,
            expiresAt: expiresAt * 1000,
            token,
        };

        store.set('authState', newAuthState);
        setAuthState(newAuthState);
    };

    useEffect(() => {
        if (!authState?.token) {
            return;
        }

        if (refreshTimeout.current) {
            clearTimeout(refreshTimeout.current);
        }

        const timeToExpiry = authState.expiresAt - Date.now();
        const timeToRefresh = timeToExpiry - TOKEN_REFRESH_THRESHOLD;

        if (timeToExpiry < 0) {
            store.remove('authState');
            setAuthState(undefined);
            expiredSession$.next();
        } else if (timeToRefresh < 0) {
            refresh();
        } else {
            refreshTimeout.current = setTimeout(refresh, timeToRefresh);
        }
    }, [authState?.token]);

    useEffect(() => {
        const authState = store.get('authState');
        if (!authState) {
            setReady(true);
            return;
        }

        const restoreSession = async () => {
            // @TODO(adam): need to attempt to renew token when restoring
            if (authState.expiresAt < Date.now()) {
                store.remove('authState');
                setReady(true);
                expiredSession$.next();
                return;
            }

            const isValid = await validateToken(apiUrl, authState.token);
            if (!isValid) {
                store.remove('authState');
                setReady(true);
                expiredSession$.next();
                return;
            }

            setAuthState(authState);
            setReady(true);
        };

        restoreSession();
    }, []);

    const switchToAccountAndRefresh = async (accountId: number) => {
        const {token: newToken, expiresAt, accountId: newAccountId} = await switchToAccount(
            apiUrl,
            authState.token,
            accountId
        );
        const newAuthState: AuthState = {
            ...authState,
            token: newToken,
            expiresAt: expiresAt * 1000,
            accountId: newAccountId,
        };
        store.set('authState', newAuthState);
        setAuthState(newAuthState);
        return newAuthState;
    };

    const api: AuthAPI = {
        expiredSession$,
        logIn: async (email: string, password: string) => {
            const {accountId, token, expiresAt} = await logIn(apiUrl, email, password);
            const authState: AuthState = {
                accountId,
                token,
                user: await getUser(apiUrl, token),
                expiresAt: expiresAt * 1000,
            };
            store.set('authState', authState);
            setAuthState(authState);
        },
        logOut: () => {
            store.remove('authState');
            setAuthState(undefined);
        },
        switchToAccount: authState?.token && switchToAccountAndRefresh,
        ...authState,
    };

    return ready ? api : undefined;
};
