import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

const TRANSITION_DURATION_MS = 500;

interface Props {
    closeOnBackgroundClick?: boolean;
    shouldClose?: boolean;
    onClose?: () => void;
    testId?: string;
}

const Popup: React.FC<Props> = ({
    children,
    closeOnBackgroundClick,
    onClose,
    shouldClose,
    testId,
}) => {
    const [open, setOpen] = useState(false);
    const wasOpen = useRef(open);

    // Use close prop to trigger a close
    useEffect(() => {
        if (open && shouldClose) {
            setOpen(false);
        }
    }, [shouldClose]);

    // When we're closing, wait before triggering 'onClose'
    useEffect(() => {
        if (open !== wasOpen.current) {
            wasOpen.current = open;
            if (!open) {
                setTimeout(onClose, TRANSITION_DURATION_MS);
            }
        }
    }, [open]);

    // First rendered frame doesn't necessarily happen until the second animation frame
    useEffect(() => {
        window.requestAnimationFrame(() =>
            window.requestAnimationFrame(() => setOpen(true))
        );
    }, []);

    return (
        <Wrap className={classnames({ open })} data-testid={testId}>
            <Content>{children}</Content>
            <Shade
                onClick={
                    closeOnBackgroundClick ? () => setOpen(false) : undefined
                }
            />
        </Wrap>
    );
};

export default Popup;

const Content = styled.div`
    background: var(--foreground);
    border-radius: 0.625rem;
    box-sizing: border-box;
    color: var(--shade-2);
    max-width: calc(100% - 2rem);
    opacity: 0;
    padding: 2.5rem;
    position: relative;
    text-align: center;
    transform: translateY(2rem);
    transition: ${TRANSITION_DURATION_MS}ms transform
            cubic-bezier(0.22, 1, 0.36, 1),
        ${TRANSITION_DURATION_MS} opacity cubic-bezier(0.22, 1, 0.36, 1);
    width: 27rem;
    z-index: 1;

    button {
        margin-top: 0.5rem;
        min-width: 15rem;
    }

    h2 {
        color: var(--tertiary);
        margin-top: 0;
        line-height: 1.15;
    }

    p {
        line-height: 1.15;
    }
`;

const Shade = styled.div`
    background: rgba(0, 0, 0, 0.8);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0;
    transition: ${TRANSITION_DURATION_MS}ms opacity
        cubic-bezier(0.22, 1, 0.36, 1);
`;

const Wrap = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 10;

    &.open {
        ${Shade} {
            opacity: 1;
        }

        ${Content} {
            transform: translateY(0);
            opacity: 1;
        }
    }
`;
