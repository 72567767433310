import styled from 'styled-components';

import Item from './Item';

const List = styled.ul`
    --item-width: 100%;
    justify-content: center;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 100%;


    :hover ${Item}.clickable {
        opacity: 0.9;

        :hover {
            opacity: 1;
        }
    }
`;

export default List;
