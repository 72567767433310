import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    :root {
        --background: #003C8E;
        --foreground: #FFFFFF;
        --tint-1: #1565BF;
        --tint-2: #D1D3D4;
        --shade-1: rgba(0, 0, 0, 0.2);
        --shade-2: #58595B;
        --primary: #8F71E9;
        --secondary: #5E91F2;
        --tertiary: #1565BF;
        --success: #56D5BF;
        --success-hsl: 170, 60%, 60%;
        --warning: #FEBD3F;
        --notice: #56D5BF;
        --input-border: 1px solid rgba(255, 255, 255, 0.5);
        --input-foreground: currentColor;
        --input-background: none;
    }

    #root {
        height: 100%;
        overflow: auto;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    button, .button {
        --button-background: var(--foreground);
        --button-color: var(--background);
        background-color: var(--button-background);
        border-radius: 1.5rem;
        border: 0 none;
        box-sizing: border-box;
        color: var(--button-color);
        cursor: pointer;
        display: inline-block;
        flex: 0 0 auto;
        font-size: 1.125rem;
        font: inherit;
        height: 3rem;
        letter-spacing: 0.05rem;
        line-height: 3rem;
        max-width: 100%;
        min-width: 12rem;
        outline: none;
        overflow: hidden;
        padding: 0 1.125rem;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        vertical-align: middle;
        white-space: nowrap;
        width: auto;

        &[disabled] {
            cursor: default;
            opacity: 0.4;
        }

        :after {
            content: '';
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            transition: 75ms background-color;
            opacity: 0;
        }

        :hover:after {
            background: rgba(255, 255, 255, 1);
            opacity: 0.1;
        }
    }

    h1, h2, h3, h4 {
        font-weight: normal;
    }

    h1 {
        font-size: 2rem;
        line-height: 1.15;

        small {
            display: block;
            font-size: 1.125rem;
            text-transform: uppercase;
            margin-bottom: 0.5rem;
            line-height: 1.3;
        }
    }

    html, body {
        background: var(--background);
        color: var(--foreground);
        height: 100%;
        line-height: 1.5;
        margin: 0;
        padding: 0;
        font-family: "Varela Round", sans-serif;
    }

    input, select {
        background: var(--input-background);
        color: var(--input-foreground);
        border: var(--input-border);
        border-radius: 0.5rem;
        box-sizing: border-box;
        font: inherit;
        font-size: ${15 / 16}rem;
        height: 3rem;
        padding: 0 1rem;
    }
`;
