import {Trans} from 'react-i18next';
import React, {useState} from 'react';
import styled from 'styled-components';

import {useBluetooth} from '../../services/bluetooth';
import Button from '../../components/Button';
import Content from '../../components/Content';
import Pairing from '../Pairing';
import astronaut from './astronaut.svg';

const CHOOSER_CANCELLED_MESSAGE = 'User cancelled the requestDevice() chooser.';
const CHOOSER_CANCELLED_NAME = 'DOMException';

interface Props {
    onPaired: () => void;
}

const Pair: React.FC<Props> = ({onPaired}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>(undefined);
    const {loadDevice, pairingStatus} = useBluetooth();
    const onPair = async () => {
        try {
            setLoading(true);
            await loadDevice();
            onPaired();
        } catch (error) {
            // @NOTE(adam): would use instanceof, but cypress and browser have two different interpretations of that
            const userCancelledPairing =
                error.constructor.name === CHOOSER_CANCELLED_NAME &&
                error.message === CHOOSER_CANCELLED_MESSAGE;

            if (!userCancelledPairing) {
                setError((error?.message ?? error) || 'Unable to pair - please try again');
            }

            setLoading(false);
        }
    };

    if (loading) {
        return <Pairing pairingStatus={pairingStatus} />;
    }

    return (
        <Wrap data-testid="pair">
            <img src={astronaut} />
            <h1>
                <Trans i18nKey="pair.title">
                    <small>Step 2:</small> Select a device to set up
                </Trans>
            </h1>
            {!!error && <Notice data-testid="pair-error">{error}</Notice>}
            <Button testId="pair-pick-device" onClick={onPair}>
                <Trans i18nKey="pair.pickDeviceButton">Pick Device</Trans>
            </Button>
        </Wrap>
    );
};

export default Pair;

const Notice = styled.p`
    color: var(--warning);
    margin: 0 0 1.5rem;
`;

const Wrap = styled(Content)`
    img {
        flex: 0 1 auto;
        margin: 0 0.5rem 1rem;
        height: auto;
        width: 100%;
        max-height: 20rem;
        max-width: 12rem;
    }
`;
