import {Observable, concat, from, interval, race, throwError} from 'rxjs';
import {
    concatMap,
    distinctUntilChanged,
    filter,
    map,
    skipWhile,
    takeWhile,
    last,
    startWith,
    switchMap,
} from 'rxjs/operators';

import {BluetoothInterface} from '../types/bluetooth';
import {DeviceConnectivity, WifiNetwork, WifiStatus} from '../types/network';
import * as bluetoothRequests from './bluetoothRequests';

export const WIFI_STATUS_POLL_INTERVAL_MS = 5000;
export const INITIAL_BATCH_SIZE = 20;
export const REGULAR_BATCH_SIZE = 200;

const NOT_CONNECTED_STATUSES = [
    WifiStatus.DISCONNECTED,
    WifiStatus.UNAUTHENTICATED,
    WifiStatus.NO_CONNECTIVITY,
];

export const getJoinNetworkStatus$ = (connectivity$: Observable<DeviceConnectivity>) =>
    connectivity$.pipe(
        // Only update status when not connected, or can connect to meld too
        filter(
            ({connectsToMeld, networkStatus}) =>
                networkStatus !== WifiStatus.CONNECTED || connectsToMeld
        ),
        map(({networkStatus}) => networkStatus),
        distinctUntilChanged()
    );

export const getNetworkJoined$ = (connectivity$: Observable<DeviceConnectivity>) => {
    const complete$ = connectivity$.pipe(
        filter(
            ({connectsToMeld, networkStatus}) =>
                networkStatus === WifiStatus.CONNECTED && connectsToMeld
        )
    );

    const cancel$ = connectivity$.pipe(last());

    const error$ = connectivity$.pipe(
        skipWhile(({networkStatus}) => networkStatus !== WifiStatus.CONNECTING),
        filter(({networkStatus}) => NOT_CONNECTED_STATUSES.includes(networkStatus))
    );

    return race(cancel$, error$, complete$).pipe(
        takeWhile(
            ({connectsToMeld, networkStatus}) =>
                networkStatus !== WifiStatus.CONNECTED || !connectsToMeld
        ),
        switchMap(connectivity => throwError(connectivity))
    );
};

export const joinWifiNetwork = (
    deviceInterface: BluetoothInterface,
    network: WifiNetwork,
    passphrase: string
) => {
    const initialStatus: DeviceConnectivity = {
        networkStatus: WifiStatus.CONNECTING,
    };

    return bluetoothRequests
        .joinWifiNetwork(
            deviceInterface,
            network.ssid ? {ssid: network.ssid, passphrase} : {bssid: network.bssid, passphrase}
        )
        .pipe(startWith(initialStatus));
};

export const searchForWifiNetworks = (deviceInterface: BluetoothInterface) =>
    concat(
        from([INITIAL_BATCH_SIZE, REGULAR_BATCH_SIZE]).pipe(
            concatMap((numberOfNetworks: number) =>
                bluetoothRequests.getWifiNetworks(deviceInterface, {
                    numberOfNetworks,
                })
            )
        ),
        interval(WIFI_STATUS_POLL_INTERVAL_MS).pipe(
            concatMap(() =>
                bluetoothRequests.getWifiNetworks(deviceInterface, {
                    numberOfNetworks: REGULAR_BATCH_SIZE,
                })
            )
        )
    );
