import { Trans } from 'react-i18next';
import React, { useState } from 'react';

import Button from '../../components/Button';
import Popup from '../../components/Popup';

interface Props {
    onClose: () => void;
}

const LostInternetPopup: React.FC<Props> = ({ onClose }) => {
    const [close, setClose] = useState(false);
    return (
        <Popup
            testId="lost-internet-popup"
            onClose={onClose}
            shouldClose={close}
        >
            <h2>
                <Trans i18nKey="popup.lostInternet.title">
                    Internet connection lost
                </Trans>
            </h2>
            <p>
                <Trans i18nKey="popup.lostInternet.instruction">
                    Your meld device has lost connection to essential online
                    services - please check your wifi connection. This popup
                    will disappear once connection has been established again,
                    or you can go back to the start and try again.
                </Trans>
            </p>
            <Button
                background="var(--tertiary)"
                color="var(--foreground)"
                onClick={() => setClose(true)}
                testId="lost-internet-popup-close"
            >
                <Trans i18nKey="popup.lostInternet.closeButton">
                    Start Again
                </Trans>
            </Button>
        </Popup>
    );
};

export default LostInternetPopup;
