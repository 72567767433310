import {Observable, Subject, combineLatest, from, fromEvent, merge, throwError} from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    finalize,
    first,
    map,
    retry,
    scan,
    share,
    shareReplay,
    switchMap,
    take,
    takeWhile,
    timeout,
} from 'rxjs/operators';

import {
    BLUETOOTH_REQUEST_WAIT_TIME,
    BLUETOOTH_RESPONSE_WAIT_TIME,
    END_MARKER,
    MELD_READ_CHARACTERISTIC_ID,
    MELD_SERVICE_UUID,
    MELD_WRITE_CHARACTERISTIC_ID,
    START_MARKER,
    send,
} from './bluetooth';
import {
    BluetoothCharacteristicChangeEvent,
    BluetoothInterface,
    BluetoothRequest,
    BluetoothResponse,
} from '../types/bluetooth';

export interface InputOutputCharacteristics {
    input?: BluetoothRemoteGATTCharacteristic;
    output?: BluetoothRemoteGATTCharacteristic;
}

const MESSAGE_SEND_RETRIES = 3;

export const callOverBluetooth = async <Data>(
    requestId: number,
    command: string,
    data: Data,
    queuedRequest$: Subject<BluetoothRequest>,
    sentRequest$: Observable<BluetoothRequest>
) => {
    const pendingRequest = sentRequest$
        .pipe(
            first(({id}) => id === requestId),
            timeout(BLUETOOTH_REQUEST_WAIT_TIME)
        )
        .toPromise()
        .then(() => undefined);

    queuedRequest$.next({
        id: requestId,
        command,
        data,
    });

    return pendingRequest;
};

export const fromOverBluetooth = <Data, ResponseData = unknown>(
    requestId: number,
    command: string,
    data: Data,
    dataType: string = undefined,
    queuedRequest$: Subject<BluetoothRequest>,
    sentRequest$: Observable<BluetoothRequest>,
    response$: Observable<BluetoothResponse<ResponseData>>,
    listenForTimeout: boolean = true
) => {
    const request: BluetoothRequest<Data> = {
        id: requestId,
        command,
        data,
    };

    const requestSent$ = sentRequest$.pipe(
        takeWhile(({id}) => id !== requestId),
        timeout(BLUETOOTH_REQUEST_WAIT_TIME)
    );

    const requestQueued$ = new Observable(observer => {
        queuedRequest$.next(request);
        observer.complete();
    });

    // @NOTE(adam): this part is critically important - the order of these means that we:
    //   1. listen for responses
    //   2. listen for sent requests
    //   3. listen for queued requests
    // Any other order and we will miss things
    const requestActivity$ = merge(
        response$,
        requestSent$.pipe(map(() => undefined)),
        requestQueued$.pipe(map(() => undefined))
    );

    const trackedActivity$ = listenForTimeout
        ? requestActivity$.pipe(timeout(BLUETOOTH_RESPONSE_WAIT_TIME))
        : requestActivity$;

    return trackedActivity$.pipe(
        filter(Boolean), // Only take responses
        filter(
            (response: BluetoothResponse<ResponseData>) =>
                request.id === response.id && (!dataType || dataType === response.dataType)
        ),
        map(({data}) => data)
    );
};

export const getConnectedGatt$ = (device: BluetoothDevice, disconnection$: Observable<void>) => {
    const device$ = merge(from([device]), disconnection$.pipe(map(() => device)));

    return device$.pipe(
        switchMap(device => {
            if (!device?.gatt) return [undefined];
            return device.gatt.connected
                ? [device.gatt]
                : from(device.gatt.connect()).pipe(catchError(() => [undefined]));
        }),
        map(gatt => (gatt?.connected ? gatt : undefined))
    );
};

export const getInputOutputCharacteristics$ = (
    service$: Observable<BluetoothRemoteGATTService>
): Observable<InputOutputCharacteristics> =>
    service$.pipe(
        switchMap(service => {
            if (!service) return from([{input: undefined, output: undefined}]);

            const input = from(service.getCharacteristic(MELD_WRITE_CHARACTERISTIC_ID)).pipe(
                catchError(() => [undefined])
            );

            const output = from(service.getCharacteristic(MELD_READ_CHARACTERISTIC_ID)).pipe(
                catchError(() => [undefined])
            );

            return combineLatest<
                [BluetoothRemoteGATTCharacteristic, BluetoothRemoteGATTCharacteristic]
            >(input, output).pipe(map(([input, output]) => ({input, output})));
        })
    );

export const getInterface = (device: BluetoothDevice): BluetoothInterface => {
    const error$ = new Subject<Error>();
    let lastRequestId = 0;

    // Connect and get input chars
    const gatt$ = getConnectedGatt$(device, error$.pipe(map(() => undefined)));
    const service$ = getMeldService$(gatt$);
    const inputOutputCharacteristics$ = getInputOutputCharacteristics$(service$).pipe(
        shareReplay(1)
    );

    // Set up request/response flow
    const response$ = getResponse$(inputOutputCharacteristics$).pipe(share());
    const queuedRequest$ = new Subject<BluetoothRequest>();
    const sentRequest$ = startSendingRequests$(queuedRequest$, inputOutputCharacteristics$, error =>
        error$.next(error)
    ).pipe(share());

    if (process.env.LOG_BLUETOOTH_MESSAGES) {
        response$.subscribe(response => console.log('[Response]', response));
        queuedRequest$.subscribe(request => console.log('[Queued]', request));
        sentRequest$.subscribe(request => console.log('[Completed]', request));
        error$.subscribe(request => console.warn('[Error]', request));
    }

    return {
        call: <Data>(command: string, data: Data) =>
            callOverBluetooth<Data>(lastRequestId++, command, data, queuedRequest$, sentRequest$),
        disconnect: () => {
            if (device.gatt.connected) device.gatt.disconnect();
        },
        disconnection$: fromEvent(device, 'gattserverdisconnected').pipe(map(() => undefined)),
        from: <Data, ResponseData>(command: string, data: Data, dataType?: string) =>
            fromOverBluetooth<Data, ResponseData>(
                lastRequestId++,
                command,
                data,
                dataType,
                queuedRequest$,
                sentRequest$,
                response$,
                false
            ),
        request: <Data, ResponseData>(command: string, data: Data, dataType?: string) =>
            fromOverBluetooth<Data, ResponseData>(
                lastRequestId++,
                command,
                data,
                dataType,
                queuedRequest$,
                sentRequest$,
                response$
            )
                .pipe(first())
                .toPromise(),
    };
};

export const getMeldService$ = (gatt$: Observable<BluetoothRemoteGATTServer | undefined>) =>
    gatt$.pipe(
        switchMap(gatt =>
            gatt
                ? from(gatt.getPrimaryService(MELD_SERVICE_UUID)).pipe(
                    catchError(() => [undefined])
                )
                : [undefined]
        )
    );

export const getResponse$ = (
    inputOutputCharacteristics$: Observable<InputOutputCharacteristics>
) => {
    const decoder = new TextDecoder('utf-8');
    const response$ = inputOutputCharacteristics$.pipe(
        filter(({output}) => Boolean(output)),
        switchMap(({output}) => {
            const value$ = fromEvent<BluetoothCharacteristicChangeEvent>(
                output,
                'characteristicvaluechanged'
            ).pipe(
                finalize(() => {
                    if (output.service.device.gatt.connected) {
                        output.stopNotifications();
                    }
                })
            );
            output.startNotifications();
            return value$;
        }),
        map((event: BluetoothCharacteristicChangeEvent) => decoder.decode(event.target.value)),
        scan((message, next) => {
            message += next;
            const hasSecondStartMarker = message.includes(START_MARKER, 1);
            return hasSecondStartMarker
                ? START_MARKER + message.split(START_MARKER).pop()
                : message;
        }),
        filter(message => message.endsWith(END_MARKER)),
        map(message =>
            JSON.parse(message.substring(START_MARKER.length, message.length - END_MARKER.length))
        )
    );

    return response$;
};

export const startSendingRequests$ = (
    queuedRequest$: Observable<BluetoothRequest>,
    inputOutputCharacteristics$: Observable<InputOutputCharacteristics>,
    onError: (error: Error) => void
) =>
    queuedRequest$.pipe(
        concatMap(request =>
            inputOutputCharacteristics$.pipe(
                map(({input}) => input),
                filter(characteristic => Boolean(characteristic)),
                switchMap(inputCharacteristic => send(request, inputCharacteristic)),
                catchError((error: Error) => {
                    onError(error);
                    return throwError(error);
                }),
                retry(MESSAGE_SEND_RETRIES),
                map(() => request),
                take(1)
            )
        )
    );
