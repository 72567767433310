import { Trans } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';

import Button, { ButtonWrap } from '../../components/Button';
import Content from '../../components/Content';
import rocket from './rocket.svg';

interface Props {
    onSetupNewDevice: () => void;
    onReplicate?: () => void;
    warning?: string;
}

const Completed: React.FC<Props> = ({
    onReplicate,
    onSetupNewDevice,
    warning,
}) => (
    <Wrap data-testid="completed">
        <img src={rocket} />
        <h1>
            <Trans i18nKey="completed.title">Device setup completed</Trans>
        </h1>
        {!!warning && (
            <Warning>
                <Trans i18nKey="completed.warning" name={warning}>
                    Not able to fully complete content loading. {warning}
                </Trans>
            </Warning>
        )}

        <Button testId="setup-new-device-button" onClick={onSetupNewDevice}>
            <Trans i18nKey="completed.newButton.label">Set up new device</Trans>
        </Button>
        <p>
            <Trans i18nKey="completed.newButton.description">
                Set up device from scratch
            </Trans>
        </p>

        {!!onReplicate && (
            <>
                <Button
                    background="var(--secondary)"
                    color="var(--foreground)"
                    testId="replicate-setup-button"
                    onClick={onReplicate}
                >
                    <Trans i18nKey="completed.replicateButton.label">
                        Replicate setup
                    </Trans>
                </Button>
                <p>
                    <Trans i18nKey="completed.replicateButton.description">
                        Copy this setup to another device{' '}
                    </Trans>
                </p>
            </>
        )}
    </Wrap>
);

export default Completed;

const Warning = styled.p`
    color: var(--warning);
`;

const Wrap = styled(Content)`
    img {
        flex: 0 1 auto;
        margin: 0 0 1rem;
        max-width: 12rem;
    }

    ${ButtonWrap} {
        margin-top: 1rem;
    }
`;
