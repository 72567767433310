import {Trans} from 'react-i18next';
import React from 'react';

import Content from '../../components/Content';
import cloud from '../Enrol/cloud.svg';

const CheckForContent: React.FC = () => (
    <Content data-testid="check-for-content">
        <img src={cloud} />
        <h1>
            <Trans i18nKey="checkForContent.title">Checking for content...</Trans>
        </h1>
    </Content>
);

export default CheckForContent;
