import styled from 'styled-components';

export default styled.div`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    justify-content: center;
    margin: 0 auto;
    max-width: 20rem;
    overflow: auto;
    padding: 1rem 2rem;
    width: 100%;

    @media (min-width: 640px) {
        max-width: 32rem;
        padding: 4rem;
    }
`;
