export type ParsableBoolean = string | boolean | number | undefined | null;

export function parseBoolean(value: ParsableBoolean, defaultValue = false) {
    const EMPTY_VALUES: unknown[] = ['', undefined, null];

    if (EMPTY_VALUES.includes(value)) {
        return defaultValue;
    }

    if (typeof value === 'string') {
        return !['false', 'FALSE'].includes(value);
    }

    return Boolean(value);
}
