import {Trans} from 'react-i18next';
import React, {useCallback, useMemo} from 'react';
import styled from 'styled-components';

import {useAuth} from '../../services/auth';
import {useBluetooth} from '../../services/bluetooth';
import Button, {ButtonWrap} from '../../components/Button';
import Content from '../../components/Content';
import cloud from '../Enrol/cloud.svg';

interface Props {
    onCancel: () => void;
    onNext: () => void;
    onRemove: () => void;
}

const AlreadyEnrolled: React.FC<Props> = ({onCancel, onNext, onRemove}) => {
    const {switchToAccount, user} = useAuth();
    const {identifiers} = useBluetooth();

    const account = useMemo(() => {
        if (!user?.accounts || !identifiers?.accountId) return;
        return user.accounts.find(account => account.id === identifiers.accountId);
    }, [identifiers, user]);

    const handleNext = useCallback(() => {
        if (!account) return;
        switchToAccount(account.id).then(() => onNext());
    }, [account, onNext, switchToAccount]);

    return (
        <Wrap data-testid={`already-enrolled-${account ? 'known' : 'unknown'}`}>
            <img src={cloud} />
            {Boolean(account) && (
                <h1>
                    <Trans i18nKey="alreadyEnrolled.title.knownAccount" name={account.name}>
                        Device was found in the account <small>&lsquo;{account.name}&rsquo;</small>
                    </Trans>
                </h1>
            )}
            {!Boolean(account) && (
                <>
                    <h1>
                        <Trans i18nKey="alreadyEnrolled.title.unknownAccount">
                            Device has already been enrolled
                        </Trans>
                    </h1>
                    <p>
                        You do not have access to this account. Request an invitation from somebody
                        in that account, or contact support.
                    </p>
                </>
            )}
            {Boolean(!account && process.env.LOG_SUPPORT_TICKET_URL) && (
                <a
                    className="button"
                    data-testid="log-ticket-button"
                    href={process.env.LOG_SUPPORT_TICKET_URL}
                    rel="noreferrer"
                    target="_blank"
                >
                    <Trans i18nKey="alreadyEnrolled.logTicketButton">Log Ticket</Trans>
                </a>
            )}
            {Boolean(account) && (
                <Button testId="next-button" onClick={handleNext}>
                    <Trans i18nKey="alreadyEnrolled.continueButton">Continue</Trans>
                </Button>
            )}
            <Buttons>
                <Button
                    testId="cancel-button"
                    onClick={onCancel}
                    background="var(--secondary)"
                    color="var(--foreground)"
                >
                    <Trans i18nKey="alreadyEnrolled.cancelButton">Cancel</Trans>
                </Button>
                {Boolean(account) && (
                    <Button
                        testId="remove-button"
                        onClick={onRemove}
                        background="var(--secondary)"
                        color="var(--foreground)"
                    >
                        <Trans i18nKey="alreadyEnrolled.removeButton">Remove</Trans>
                    </Button>
                )}
            </Buttons>
        </Wrap>
    );
};

export default AlreadyEnrolled;

const Buttons = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;

    .button + &,
    ${ButtonWrap} + & {
        margin-top: 1rem;
    }

    .button,
    ${ButtonWrap} {
        min-width: 0;

        + .button,
        + ${ButtonWrap} {
            margin-left: 1rem;
        }
    }
`;

const Wrap = styled(Content)`
    padding: 1rem 2rem;
    
    h1 + p {
        margin: 0 0 2rem;
    }

    img {
        flex: 0 1 auto;
        margin: 0 0 1rem;
    }

    small {
        text-transform: none;
        font-size: 0.75em;
        margin-top: 0.5rem;
    }
`;
