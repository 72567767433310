import { LanguagePairs } from '../types/language';

const fallbackTranslations: LanguagePairs = {
    connectToNetwork: {
        passwordFieldLabel: 'Enter password',
    },
    logIn: {
        emailFieldLabel: 'Email address',
        passwordFieldLabel: 'Password',
    },
    selectNetwork: {
        customSsidLabel: 'Or enter custom SSID'
    }
};

export default fallbackTranslations;
