import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

interface Props {
    color?: string;
    background?: string;
    children?: React.ReactNode;
    expanding?: boolean;
    onClick?: (event: React.MouseEvent) => void;
    size?: 'small' | 'normal' | 'large';
    style?: React.CSSProperties;
    type?: 'filled' | 'outlined';
    testId?: string;
}

const Button: React.FC<Props> = ({
    background,
    children,
    color,
    expanding,
    onClick,
    size,
    style,
    type,
    testId,
}) => (
    <Wrap
        className={classnames([size, type], { expanding })}
        data-testid={testId}
        disabled={!onClick}
        style={
            {
                ...style,
                '--button-background': background,
                '--button-color': color,
            } as React.CSSProperties
        }
        onClick={onClick}
    >
        {React.Children.map(children, child =>
            typeof child === 'string' ? <span>{child}</span> : child
        )}
    </Wrap>
);

export const Wrap = styled.button``;

export const ButtonWrap = Wrap;

export default Button;
