import {Observable, interval, lastValueFrom} from 'rxjs';
import {exhaustMap, first} from 'rxjs/operators';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import createService from '@meldcx/react-service';

import {BluetoothDeviceAPI, BluetoothInterface} from '../types/bluetooth';
import {DeviceIdentifiers} from '../types/device';
import useBluetoothAPI, {BluetoothAPI} from '../hooks/useBluetoothAPI';
import useBluetoothDeviceAPI from '../hooks/useBluetoothDeviceAPI';

interface BluetoothServiceAPI extends BluetoothAPI, BluetoothDeviceAPI {
    isConnected: boolean;
    loadDevice: () => Promise<void>;
    loadIdentifiers: () => Promise<DeviceIdentifiers>;
    closeDevice: () => void;
    device: BluetoothDevice;
    deviceDisconnection$?: Observable<void>;
    identifiers?: DeviceIdentifiers;
    reset: () => void;
}

const useBluetoothServiceAPI = () => {
    const [device, setDevice] = useState<BluetoothDevice>();
    const [identifiers, setIdentifiers] = useState<DeviceIdentifiers>();
    const bluetoothInterface = useRef<BluetoothInterface>();
    const bluetoothApi = useBluetoothAPI();
    const bluetoothDeviceApi = useBluetoothDeviceAPI(bluetoothInterface.current);

    const {isAvailable} = bluetoothApi;

    useEffect(() => {
        if (!device && bluetoothInterface.current) {
            bluetoothInterface.current?.disconnect();
            bluetoothInterface.current = undefined;
        }
    }, [device, bluetoothInterface]);

    const useAvailableCallback = <TFunction extends (...args: any[]) => any>(
        callback: TFunction,
        deps: unknown[]
    ): TFunction => {
        return useCallback(
            (...args: unknown[]) => {
                if (!isAvailable) {
                    throw new Error('Bluetooth is not available');
                }
                return callback(...args);
            },
            [isAvailable, ...deps]
        ) as TFunction;
    };

    const isConnected = Boolean(isAvailable && device);

    const useConnectedCallback = <TFunction extends (...args: any[]) => any>(
        callback: TFunction,
        deps: unknown[]
    ): TFunction => {
        return useCallback(
            (...args: unknown[]) => {
                if (!isAvailable) {
                    throw new Error('Bluetooth is not available');
                }
                if (!isConnected) {
                    throw new Error('Not connected to device');
                }
                return callback(...args);
            },
            [isAvailable, isConnected, ...deps]
        ) as TFunction;
    };

    const closeDevice = useConnectedCallback(() => {
        setDevice(undefined);
    }, [setDevice]);

    const reset = useCallback(() => {
        if (device) setDevice(undefined);
        if (identifiers) setIdentifiers(undefined);
    }, [device, setDevice]);

    const loadDevice = useAvailableCallback(async () => {
        const {
            device: newDevice,
            bluetoothInterface: newBluetoothInterface,
        } = await bluetoothApi.getDevice();

        bluetoothInterface.current = newBluetoothInterface;
        setDevice(newDevice);
    }, [bluetoothApi.getDevice, setDevice]);

    const loadIdentifiers = useConnectedCallback(async () => {
        const identifiers$ = interval(1000).pipe(
            exhaustMap(() => bluetoothDeviceApi.getDeviceIdentifiers()),
            first(({callHomeId}) => Boolean(callHomeId))
        );

        const identifiers = await lastValueFrom(identifiers$);
        setIdentifiers(identifiers);
        return identifiers;
    }, [bluetoothDeviceApi.getDeviceIdentifiers]);

    const deviceDisconnection$ = bluetoothInterface?.current?.disconnection$;

    const api = useMemo((): BluetoothServiceAPI => {
        return {
            ...bluetoothApi,
            ...bluetoothDeviceApi,
            closeDevice,
            device,
            deviceDisconnection$,
            identifiers,
            isConnected,
            loadDevice,
            loadIdentifiers,
            reset,
        };
    }, [
        bluetoothApi,
        bluetoothDeviceApi,
        closeDevice,
        device,
        deviceDisconnection$,
        identifiers,
        isConnected,
        loadDevice,
        loadIdentifiers,
        reset,
    ]);

    return api;
};

const [BluetoothService, useBluetooth, {Provider: BluetoothProvider}] = createService(
    useBluetoothServiceAPI
);

export {BluetoothProvider, BluetoothService, BluetoothServiceAPI, useBluetooth};
