import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import { useTabs } from './';

interface Props {
    label: string;
    tabTestId?: string;
}

const TabScreen: React.FC<Props> = ({ children, label, tabTestId }) => {
    const tabId = useRef<number>(undefined);
    const { activeTabId, updateTab } = useTabs();
    useEffect(() => {
        const tabInfo = {
            label,
            testId: tabTestId
        };

        tabId.current = updateTab(tabId.current, tabInfo);
    }, [label]);

    return (
        <Wrap className={classnames({ active: activeTabId === tabId.current })}>
            {children}
        </Wrap>
    );
};

export default TabScreen;

const Wrap = styled.div`
    flex: 0 0 auto;
    height: 100%;
    opacity: 0;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-3px) scale(4);
    width: 100%;
    will-change: opacity;
    z-index: 1;

    &.active {
        opacity: 1;
        z-index: 2;
    }
`;

export const TabScreenWrap = Wrap;
