import {map} from 'rxjs/operators';

import {BluetoothInterface} from '../types/bluetooth';
import {DeviceConnectivity, WifiNetwork, WifiStatus} from '../types/network';
import {DeviceIdentifiers} from '../types/device';
import {UrlInfo} from '../types/url';

type AuthenticateRequestData = {authenticationData: string};
type AuthenticateResponseData = {authenticated: boolean};

export const authenticate = (deviceInterface: BluetoothInterface, authenticationData: string) =>
    deviceInterface
        .request<AuthenticateRequestData, AuthenticateResponseData>('authenticate', {
            authenticationData,
        })
        .then(response => response.authenticated);

type GetAuthenticationChallengeResponseData = {
    authenticationChallenge: string;
    deviceId?: number;
};

export const getAuthenticationChallenge = (deviceInterface: BluetoothInterface) =>
    deviceInterface
        .request<{}, GetAuthenticationChallengeResponseData>('getAuthenticationChallenge', {})
        .then(({authenticationChallenge, deviceId}) => ({
            challenge: authenticationChallenge,
            deviceId,
        }));

export const getDeviceIdentifiers = (deviceInterface: BluetoothInterface) =>
    deviceInterface.request<{}, DeviceIdentifiers>('getDeviceIdentifiers', {}, 'deviceIdentifiers');

type GetUrlInfoRequestData = {url: string};

export const getUrlInfo = (
    deviceInterface: BluetoothInterface,
    requestData: GetUrlInfoRequestData
) => deviceInterface.request<GetUrlInfoRequestData, UrlInfo>('urlCheck', requestData);

enum ConnectionStatus {
    DISCONNECTED = 'Not Connected',
    CONNECTING = 'Connecting',
    CONNECTED = 'Connected',
    UNAVAILABLE = 'Unavailable',
}

const WIFI_STATUSES: Record<ConnectionStatus, WifiStatus> = {
    [ConnectionStatus.DISCONNECTED]: WifiStatus.DISCONNECTED,
    [ConnectionStatus.CONNECTING]: WifiStatus.CONNECTING,
    [ConnectionStatus.CONNECTED]: WifiStatus.CONNECTED,
    [ConnectionStatus.UNAVAILABLE]: WifiStatus.UNAUTHENTICATED,
};

type JoinWifiNetworkResponseData = {
    connectionStatus: ConnectionStatus;
    connectsToMeld: boolean | null;
};

type JoinWifiNetworkRequestData =
    | {bssid: string; passphrase: string}
    | {ssid: string; passphrase: string};

export const joinWifiNetwork = (
    deviceInterface: BluetoothInterface,
    requestData: JoinWifiNetworkRequestData
) =>
    deviceInterface
        .from<JoinWifiNetworkRequestData, JoinWifiNetworkResponseData>('connectWifi', {
            bssid: '',
            ...requestData,
        })
        .pipe(
            map((data: JoinWifiNetworkResponseData) => {
                const connectivity: DeviceConnectivity = {
                    networkStatus: WIFI_STATUSES[data.connectionStatus],
                    connectsToMeld: data.connectsToMeld === null ? undefined : data.connectsToMeld,
                };

                return connectivity;
            })
        );

type GetProtocolVersionResponseData = {protocolVersion: number};

export const getProtocolVersion = (deviceInterface: BluetoothInterface) =>
    deviceInterface
        .request<{}, GetProtocolVersionResponseData>('getProtocolVersion', {})
        .then(response => response.protocolVersion);

type GetWifiNetworksRequestData = {numberOfNetworks?: number};

type GetWifiNetworksResponseData = {
    numberOfNetworks: number;
    networks: WifiNetwork[];
};

export const getWifiNetworks = (
    deviceInterface: BluetoothInterface,
    requestData: GetWifiNetworksRequestData
) =>
    deviceInterface
        .request<GetWifiNetworksRequestData, GetWifiNetworksResponseData>(
            'getWifiNetworks',
            requestData
        )
        .then(response => response.networks);

type SetLanguageRequestData = {
    language: string;
    strings: Record<string, string>;
};

export const setLanguage = (
    deviceInterface: BluetoothInterface,
    requestData: SetLanguageRequestData
) => deviceInterface.call<SetLanguageRequestData>('setLanguage', requestData);

type SetTimezoneRequestData = {
    timezone: string;
};

export const setTimezone = (deviceInterface: BluetoothInterface, timezone: string) =>
    deviceInterface.call<SetTimezoneRequestData>('setTimezone', {timezone});
