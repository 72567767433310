export interface AppContent {
    id: number;
    icon: string;
    name: string;
    type: DeviceContentType.APP;
}

export interface ImageContent {
    type: DeviceContentType.IMAGE;
    url: string;
}

export interface UrlContent {
    type: DeviceContentType.URL;
    url: string;
}

export interface VideoContent {
    type: DeviceContentType.VIDEO;
    url: string;
}

export type DeviceContent =
    | ImageContent
    | VideoContent
    | AppContent
    | UrlContent;

export enum DeviceContentType {
    APP = 'app',
    IMAGE = 'image',
    URL = 'url',
    VIDEO = 'video',
}
