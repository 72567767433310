import { Subscription } from 'rxjs';
import { Trans, useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import { WifiNetwork } from '../../types/network';
import { useBluetooth } from '../../services/bluetooth';
import Button from '../../components/Button';
import Content from '../../components/Content';
import Field, { FieldWrap } from '../../components/Field';
import Input from '../../components/Input';
import Item from '../../components/List/Item';
import List from '../../components/List';
import Signal, { BarWrap, SignalWrap } from '../../components/Signal';
import helmet from './helmet.svg';

interface Props {
    onSelect: (network: WifiNetwork) => void;
}

const SelectNetwork: React.FC<Props> = ({ onSelect }) => {
    const api = useBluetooth();
    const { t } = useTranslation();

    const [customSsid, setCustomSsid] = useState<string>();
    const [highlightedNetwork, setHighlightedNetwork] = useState<
        WifiNetwork | undefined
    >();

    const { networks, searchForWifiNetworks, alreadyJoined } = api;

    useEffect(() => {
        let subscription: Subscription;
        searchForWifiNetworks().then((newSubscription: Subscription) => {
            subscription = newSubscription;
        });
        return () => subscription?.unsubscribe();
    }, []);

    const onHighlight = (network: WifiNetwork) => {
        setHighlightedNetwork(
            highlightedNetwork?.ssid === network.ssid ? undefined : network
        );
    };

    const onChangeCustomSsid = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomSsid(event.target.value);
        const customNetwork: WifiNetwork = { ssid: event.target.value };
        setHighlightedNetwork(customNetwork);
    };

    const onClickCustomSsid = () => {
        if (!customSsid) {
            return setHighlightedNetwork(undefined);
        }

        const customNetwork: WifiNetwork = { ssid: customSsid };
        setHighlightedNetwork(customNetwork);
    };

    const handleSelectNetwork = (network: any) => {
        alreadyJoined(network);
        onSelect(network);
    };

    return (
        <Wrap data-testid="select-network">
            <ScrollArea>
                <img src={helmet} />
                <h1>
                    <Trans i18nKey="selectNetwork.title">
                        <small>Step 3:</small> Connect device to internet
                    </Trans>
                </h1>
                {!networks.length && (
                    <Loading>
                        <Trans i18nKey="selectNetwork.searchingNotice">
                            Searching for Wifi networks...
                        </Trans>
                    </Loading>
                )}
                {!!networks.length && (
                    <List>
                        {networks.map((network: WifiNetwork) => (
                            <Item
                                data-testid="select-network-option"
                                className={classnames({
                                    active:
                                        network.ssid ===
                                        highlightedNetwork?.ssid,
                                    clickable: true,
                                })}
                                onClick={() => onHighlight(network)}
                                key={network.ssid}
                            >
                                <span>{network.ssid}</span>
                                <Signal strength={network.signalStrength} />
                            </Item>
                        ))}
                    </List>
                )}
            </ScrollArea>
            <Field placeholder={t('selectNetwork.customSsidLabel')}>
                <Input
                    type="text"
                    className={classnames({
                        active: customSsid && customSsid === highlightedNetwork?.ssid,
                    })}
                    data-testid="select-network-custom"
                    onChange={onChangeCustomSsid}
                    onClick={onClickCustomSsid}
                    value={customSsid}
                />
            </Field>
            <Button
                testId="select-network-confirm"
                onClick={
                    highlightedNetwork
                        ? () => handleSelectNetwork(highlightedNetwork)
                        : undefined
                }
            >
                <Trans i18nKey="selectNetwork.selectButton">Select</Trans>
            </Button>
        </Wrap>
    );
};

export default SelectNetwork;

const Loading = styled.div`
    flex: 1 0 auto;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ScrollArea = styled.div`
    flex: 1 1;
    overflow: auto;
    margin: -1rem -2rem 1rem;
    padding: 1rem 2rem 0;
    max-width: 100%;
`;

const Wrap = styled(Content)`
    max-height: 100%;

    h1 {
        margin-top: 0.75rem;
    }

    img {
        height: 4rem;
        margin-top: 1rem;
    }

    input {
        height: 3.5rem;

        &.active {
            border: 2px solid var(--warning);
            padding-left: calc(1rem - 1px);
        }
    }

    ${FieldWrap} {
        margin: 0 0 1rem;
        max-width: none;
        width: calc(100% - 0.5rem);

        label {
            top: 1rem;
        }
    }

    ${List} {
        margin-bottom: 1rem;
        width: 100%;
        flex: 1 0;
        text-align: left;
    }

    ${Item}.active ${BarWrap} {
        background: #fff;
        opacity: 0.5;

        &.active {
            opacity: 1;
        }
    }

    ${SignalWrap} {
        flex: 0 0 auto;
    }
`;
