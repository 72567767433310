import { Trans } from 'react-i18next';
import React from 'react';

import Content from '../../components/Content';

const Unsupported: React.FC = () => (
    <Content data-testid="unsupported">
        <h1>
            <Trans i18nKey="unsupported.title">Browser not supported</Trans>
        </h1>
        <p>
            <Trans i18nKey="unsupported.explanation">
                This browser doesn&apos;t support required features – try
                opening this app in a different browser.
            </Trans>
        </p>
    </Content>
);

export default Unsupported;
