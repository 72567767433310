import {from} from 'rxjs';
import {useEffect, useState} from 'react';

import {Account} from '../types/account';
import {useMeld} from '../services/meld';

export const useAccounts = (): Account[] | undefined => {
    const [accounts, setAccounts] = useState<Account[]>();
    const meld = useMeld();

    useEffect(() => {
        const accounts$ = from(meld.getAccounts());
        const subscription = accounts$.subscribe(accounts => setAccounts(accounts));
        return () => subscription.unsubscribe();
    }, []);

    return accounts;
};
