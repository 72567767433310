import {Observable, Subject, Subscription} from 'rxjs';
import {first, share, takeUntil} from 'rxjs/operators';
import {useEffect, useRef, useState} from 'react';

import {BluetoothDeviceAPI, BluetoothInterface} from '../types/bluetooth';
import {DeviceConnectivity, WifiNetwork, WifiStatus} from '../types/network';
import {getDeviceIdentifiers, getUrlInfo} from '../helpers/bluetoothRequests';
import {
    getJoinNetworkStatus$,
    getNetworkJoined$,
    joinWifiNetwork,
    searchForWifiNetworks,
} from '../helpers/bluetoothWifi';

export default (bluetoothInterface?: BluetoothInterface) => {
    const cancel$ = useRef<Subject<void>>();
    const join$ = useRef<Subject<void>>();

    useEffect(() => {
        cancel$.current = new Subject();
        join$.current = new Subject();
        return () => {
            cancel$.current.complete();
            join$.current.complete();
        };
    }, []);

    const wifiNetworksSubscription = useRef<Subscription>();

    // @TODO(adam): investigate how to retrieve currently connected network
    const [network, setNetwork] = useState<WifiNetwork | undefined>(undefined);
    const [networks, setNetworks] = useState<WifiNetwork[]>([]);
    const [connectivity$, setConnectivity$] = useState<Observable<DeviceConnectivity>>();
    const [joinStatus, setJoinStatus] = useState<WifiStatus>(WifiStatus.DISCONNECTED);
    const network$ = useRef<Subject<WifiNetwork>>();

    useEffect(() => {
        if (!network$.current) {
            network$.current = new Subject<WifiNetwork>();
        }
        network$.current.next(network);
    }, [network]);

    const handleAlreadyJoined = (network: WifiNetwork) => {
        setNetwork(network);
    };

    const join = async (network: WifiNetwork, passphrase: string) => {
        join$.current.next();

        const connectivity$ = joinWifiNetwork(bluetoothInterface, network, passphrase).pipe(
            takeUntil(join$.current),
            share()
        );

        const joinConnectivity$ = connectivity$.pipe(takeUntil(cancel$.current));

        setConnectivity$(connectivity$);

        getJoinNetworkStatus$(joinConnectivity$).subscribe(status => setJoinStatus(status));

        try {
            await getNetworkJoined$(joinConnectivity$).toPromise();
        } catch (error) {
            setNetwork(undefined);
            setJoinStatus(WifiStatus.DISCONNECTED);
            throw error;
        }

        setNetwork(network);

        // Wait until network set in state
        await network$.current.pipe(first(newNetwork => newNetwork === network)).toPromise();

        return network;
    };

    const search = async () => {
        wifiNetworksSubscription.current?.unsubscribe();
        const networks$ = await searchForWifiNetworks(bluetoothInterface);
        const subscription = networks$.subscribe(setNetworks);
        wifiNetworksSubscription.current = subscription;
        return subscription;
    };

    const api: BluetoothDeviceAPI = {
        cancelJoin: () => cancel$.current.next(),
        deviceConnectivity$: connectivity$,
        getDeviceIdentifiers: () => getDeviceIdentifiers(bluetoothInterface),
        getUrlInfo: (url: string) => getUrlInfo(bluetoothInterface, {url}),
        joinStatus,
        alreadyJoined: handleAlreadyJoined,
        joinWifiNetwork: join,
        network,
        networks,
        searchForWifiNetworks: search,
    };

    return api;
};
