import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import {
    AppContent,
    DeviceContent,
    DeviceContentType
} from '../../types/content';
import Item from '../../components/List/Item';
import List from '../../components/List';
import TabScreen from '../../components/Tabs/TabScreen';

interface Props {
    onSelect: (selected?: AppContent) => void;
    options: AppContent[];
    selected?: DeviceContent;
}

const AppTabScreen: React.FC<Props> = ({ onSelect, options, selected }) => (
    <TabScreen label="App" tabTestId="app-tab">
        <TabList style={{ '--item-width': '18rem' } as any}>
            {options.length ? options.map((appContent, i) => (
                <Item
                    key={i}
                    data-testid="app-option"
                    className={classnames({
                        active:
                            selected?.type === DeviceContentType.APP &&
                            appContent.id === selected?.id,
                    })}
                    onClick={() => onSelect(appContent)}
                >
                    <img src={appContent.icon} />
                    <span>{appContent.name}</span>
                </Item>
            )) : (
                <Item
                    data-testid="app-option"
                    className={classnames({ active: selected?.type === DeviceContentType.APP })}
                >
                    No available apps.
                </Item>
            )}
        </TabList>
    </TabScreen>
);

export default AppTabScreen;

const TabList = styled(List)`
    max-width: 64rem;
    padding: 2rem;
    text-align: left;

    img {
        border-radius: 1.25rem;
        height: 2.5rem;
        overflow: hidden;
        width: 2.5rem;
        flex: 0 0 auto;
        margin-right: 1rem;
    }

    @media (min-width: 640px) {
        padding: 4rem;
    }
`;
