import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';

import {
    LanguagePairs,
    LanguageResource,
    LanguageResponse,
    LanguagesResponse,
} from '../types/language';

export const WEB_LANGUAGE_NAMESPACE = 'enrolment-web';
const LANGUAGE_DOWNLOAD_TIMEOUT_MS = 5000;

export const unpackLanguageData = (
    pairs: Record<string, string>,
    into: LanguagePairs = {}
) => {
    const unpacked: LanguagePairs = JSON.parse(JSON.stringify(into));
    for (const [key, value] of Object.entries(pairs)) {
        const parts = key.split('.');
        const top = parts.pop();
        let section: LanguagePairs = unpacked;
        while (parts.length) {
            const part = parts.shift();
            if (!(part in section)) {
                section[part] = {};
            }
            if (typeof section[part] !== 'string') {
                section = section[part] as LanguagePairs;
            }
        }
        section[top] = value;
    }

    return unpacked;
};

export const getLanguageResources = async (
    apiUrl: string,
    fallbackTranslations: LanguagePairs,
    timeout = LANGUAGE_DOWNLOAD_TIMEOUT_MS
) => {
    try {
        // Get available languages
        const request = await fetch(`${apiUrl}/languages?scope=${WEB_LANGUAGE_NAMESPACE}`, {
            headers: new Headers({
                'content-type': 'application/json',
            }),
        });

        const response = await Promise.race([
            request,
            new Promise<Response>((_, reject) => setTimeout(reject, timeout)),
        ]);

        const { data: packs }: LanguagesResponse = await response.json();
        const resources: LanguageResource = {};
        for (const pack of packs) {
            const { language, strings: pairs } = pack;
            resources[language] = {
                [WEB_LANGUAGE_NAMESPACE]: unpackLanguageData(
                    pairs,
                    fallbackTranslations
                ),
            };
        }

        return resources;
    } catch {
        return { en: { [WEB_LANGUAGE_NAMESPACE]: fallbackTranslations } };
    }
};

export const getLanguageSpec = async (
    apiUrl: string,
    language: string,
    scope: string
) => {
    const response = await fetch(
        `${apiUrl}/languages/${language}?scope=${scope}`,
        { headers: new Headers({ 'content-type': 'application/json' }) }
    );

    const { data: pack }: LanguageResponse = await response.json();
    return pack;
};

export const loadLanguages = async (
    apiUrl: string,
    fallbackTranslations: LanguagePairs
) => {
    const resources: LanguageResource = await getLanguageResources(
        apiUrl,
        fallbackTranslations
    );

    await i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .use(LanguageDetector)
        .init({
            fallbackLng: 'en',
            defaultNS: WEB_LANGUAGE_NAMESPACE,
            resources,
            postProcess: 'simple-html',
            react: {
                transSupportBasicHtmlNodes: true,
                transKeepBasicHtmlNodesFor: ['small'],
            },
        });
};
