import { Trans } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';

import { Account } from '../../types/account';
import Button from '../../components/Button';
import Content from '../../components/Content';
import cloud from '../Enrol/cloud.svg';

interface Props {
    account: Account;
    onNext: () => void;
}

const Enrolled: React.FC<Props> = ({ account, onNext }) => (
    <Wrap data-testid="enrolled">
        <img src={cloud} />
        <h1>
            <Trans i18nKey="enrolled.title" name={account.name}>
                Device now added to <small>&lsquo;{account.name}&rsquo;</small>
            </Trans>
        </h1>
        <Button testId="next-button" onClick={onNext}>
            <Trans i18nKey="enrolled.continueButton">Continue</Trans>
        </Button>
    </Wrap>
);

export default Enrolled;

const Wrap = styled(Content)`
    img {
        flex: 0 1 auto;
        margin: 0 0 1rem;
    }

    small {
        text-transform: none;
        font-size: 0.75em;
        margin-top: 0.5rem;
    }
`;
