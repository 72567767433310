import React from 'react';
import styled from 'styled-components';

import logo from './logo.svg';

const Footer: React.FC = () => (
    <Wrap>
        <Logo src={logo} />
    </Wrap>
);

export default Footer;

const Logo = styled.img`
    grid-column: 2;
    max-height: 1.5rem;
    width: auto;
`;

const Wrap = styled.div`
    align-content: center;
    display: grid;
    flex: 0 0 auto;
    grid-gap: 0.5rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
    margin-top: 1rem;
`;
