import {BrowserRouter as Router} from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom';

import {AuthService} from './services/auth';
import {BluetoothService} from './services/bluetooth';
import {MeldService} from './services/meld';
import {MonitoringService} from './services/monitoring';
import {ReplayService} from './services/replay';
import {loadLanguages} from './helpers/language';
import App from './containers/App';
import fallbackTranslations from './helpers/fallbackTranslations';
import {parseBoolean} from './helpers/boolean';

const run = async () => {
    const apiUrl = process.env.API_URL || 'https://canary.api.meld.cx/v1';
    await loadLanguages(apiUrl, fallbackTranslations);

    ReactDOM.render(
        <AuthService apiUrl={apiUrl}>
            <MonitoringService
                token={process.env.ROLLBAR_TOKEN}
                enabled={parseBoolean(process.env.MONITOR_ERRORS, false)}
            >
                <MeldService apiUrl={apiUrl}>
                    <BluetoothService>
                        <ReplayService>
                            <Router>
                                <App />
                            </Router>
                        </ReplayService>
                    </BluetoothService>
                </MeldService>
            </MonitoringService>
        </AuthService>,
        document.getElementById('root')
    );
};

run();
