import { Trans } from 'react-i18next';
import React, { useEffect, useRef } from 'react';

import Button from '../../components/Button';
import Content from '../../components/Content';

// Types
import {BluetoothInterface} from '../../types/bluetooth';

// Services
import {useBluetooth} from '../../services/bluetooth';
interface Props {
    onNext: () => void;
}

const Welcome: React.FC<Props> = ({ onNext }) => {
    const bluetoothInterface = useRef<BluetoothInterface>();
    const { closeDevice, device } = useBluetooth();

    useEffect(() => {
        if (bluetoothInterface.current) bluetoothInterface.current.disconnect();
        if (device) closeDevice();
    }, []);

    return (
        <Content data-testid="welcome">
            <h1>
                <Trans i18nKey="welcome.title">Let&apos;s enrol a new device</Trans>
            </h1>
            <Button testId="next-button" onClick={onNext}>
                <Trans i18nKey="welcome.startButton">Start</Trans>
            </Button>
        </Content>
    );
};

export default Welcome;
