import {Trans} from 'react-i18next';
import {from} from 'rxjs';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

import {AppContent, DeviceContent, DeviceContentType} from '../../types/content';
import {Application, ApplicationType} from '../../types/application';
import {TabScreenWrap} from '../../components/Tabs/TabScreen';
import {getAppContentFromApplication} from '../../helpers/application';
import {useBluetooth} from '../../services/bluetooth';
import {useMeld} from '../../services/meld';
import {useReplay} from '../../services/replay';
import AppTabScreen from './AppTabScreen';
import Button, {ButtonWrap} from '../../components/Button';
import Content from '../../components/Content';
import Tabs, {Menu as TabMenu} from '../../components/Tabs';
import UrlTabScreen from './UrlTabScreen';

interface Props {
    onNext: (error?: string) => void;
    onSkip: () => void;
}

const CONTENT_TYPES = [DeviceContentType.APP, DeviceContentType.URL];

const SelectContent: React.FC<Props> = ({onNext, onSkip}) => {
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [appContentOptions, setAppContentOptions] = useState<AppContent[]>([]);

    const {getApplicationsByType, loadContent} = useMeld();
    const {rememberContent} = useReplay();
    const {identifiers} = useBluetooth();
    const [selected, setSelected] = useState<DeviceContent>();
    const [type, setType] = useState<DeviceContentType>(CONTENT_TYPES[0]);

    useEffect(() => {
        const subscription = from<Promise<Application[]>>(
            getApplicationsByType([ApplicationType.ANDROID, ApplicationType.URL])
        ).subscribe(applications => {
            setAppContentOptions(
                applications.map(application => getAppContentFromApplication(application))
            );
        });
        return () => subscription.unsubscribe();
    }, []);

    const onSelect = async () => {
        const {deviceId} = identifiers;

        try {
            setLoading(true);
            await loadContent(deviceId, selected);
        } catch (error) {
            // @NOTE(adam): we can skip the final steps and still remember config if no displays
            if (error.name === 'DisplayMissingError') {
                rememberContent(selected);
                onNext(error.message);
            } else {
                setError(error.message);
                setLoading(false);
            }
            return;
        }
        rememberContent(selected);
        onNext();
    };

    return (
        <Wrap data-testid="select-content">
            <Tabs onChange={index => setType(CONTENT_TYPES[index])}>
                <AppTabScreen
                    selected={selected}
                    onSelect={setSelected}
                    options={appContentOptions}
                />
                <UrlTabScreen selected={selected} onSelect={setSelected} />
            </Tabs>

            {error && <ErrorStatus>{error}</ErrorStatus>}

            <Buttons>
                <Button
                    background="var(--secondary)"
                    color="var(--foreground)"
                    onClick={!loading ? onSkip : undefined}
                    testId="skip-button"
                >
                    <Trans i18nKey="selectContent.skipButton">Skip</Trans>
                </Button>
                <Button
                    onClick={!loading && selected?.type === type ? onSelect : undefined}
                    testId="select-button"
                >
                    {!loading && <Trans i18nKey="selectContent.selectButton">Select</Trans>}
                    {loading && <Trans i18nKey="selectContent.loadingButton">Loading...</Trans>}
                </Button>
            </Buttons>
        </Wrap>
    );
};

export default SelectContent;

const Buttons = styled.div`
    display: flex;
    margin: 1rem auto;
    max-width: 18rem;
    width: 100%;

    ${ButtonWrap} {
        flex: 2 1 0;
        min-width: 0;

        + ${ButtonWrap} {
            flex: 3 1 0;
            margin-left: 1rem;
        }
    }
`;

const ErrorStatus = styled.p`
    color: var(--warning);
`;

const Wrap = styled(Content)`
    padding: 0;
    margin: 0;
    max-width: none;
    width: 100%;

    @media (min-width: 640px) {
        max-width: none;
    }

    ${TabMenu} {
        --nudge: 4rem;
    }

    ${TabScreenWrap} {
        display: flex;
        flex-direction: column;
        overflow: auto;
    }
`;
