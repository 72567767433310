import { Trans } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';

import Button from '../../components/Button';
import Content from '../../components/Content';
import connect from './connect.svg';

interface Props {
    onNext: () => void;
}

const Paired: React.FC<Props> = ({ onNext }) => (
    <Wrap data-testid="paired">
        <img src={connect} />
        <h1>
            <Trans i18nKey="paired.title">Securely connected!</Trans>
        </h1>
        <Button testId="paired-next" onClick={onNext}>
            <Trans i18nKey="paired.continueButton">Continue</Trans>
        </Button>
    </Wrap>
);

export default Paired;

const Wrap = styled(Content)`
    img {
        flex: 0 1 auto;
        margin: 0 -2rem 1rem;
    }
`;
