import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';

import {DeviceContent, DeviceContentType, UrlContent} from '../../types/content';
import {useBluetooth} from '../../services/bluetooth';
import BaseContent from '../../components/Content';
import Field from '../../components/Field';
import Input from '../../components/Input';
import TabScreen from '../../components/Tabs/TabScreen';

// Thanks to 3limin4t0r for this one (https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url)
const URL_PATTERN = new RegExp(
    '^(https?:\\/\\/)((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$',
    'i'
);

const HAS_PROTOCOL_PATTERN = /^https?:\/\//;

const isUrl = (url: string) => URL_PATTERN.test(url);

interface Props {
    onSelect: (selected?: UrlContent) => void;
    selected?: DeviceContent;
}

interface WarningInfo {
    type: string;
    message: string;
}

const UrlTabScreen: React.FC<Props> = ({onSelect, selected}) => {
    const [notice, setNotice] = useState<string>();
    const [suggestion, setSuggestion] = useState<string>();
    const [url, setUrl] = useState<string>('');
    const [validating, setValidating] = useState(false);
    const [warning, setWarning] = useState<WarningInfo>();
    const validationTimeout = useRef<number>();

    const {getUrlInfo} = useBluetooth();

    useEffect(() => {
        if (!url) {
            return;
        }

        if (selected && selected?.type !== DeviceContentType.URL) {
            setUrl('');
        }
    }, [selected]);

    const queueValidateUrl = async (newUrl: string) => {
        setValidating(true);
        validationTimeout.current = setTimeout(() => validateUrl(newUrl), 1000);
    };

    const validateUrl = async (newUrl: string) => {
        const {statusCode, resolvedUrl} = await getUrlInfo(newUrl);
        const statusCodeGroup = Math.floor(statusCode / 100);

        if (statusCodeGroup === 3 && resolvedUrl !== newUrl) {
            setSuggestion(resolvedUrl);
        } else if (statusCodeGroup === 2) {
            setNotice('URL looks good!');
        } else if (statusCode === 404) {
            setWarning({
                type: 'missing',
                message: 'Warning: URL does not exist',
            });
        } else if (statusCode === 403) {
            setWarning({
                type: 'unauthorised',
                message: 'Warning: URL requires authentication',
            });
        } else if (statusCodeGroup === 4) {
            setWarning({
                type: 'broken',
                message: 'Warning: URL appears to be broken',
            });
        } else if (statusCodeGroup === 5) {
            setWarning({
                type: 'server-error',
                message: 'Warning: URL is showing server errors',
            });
        }

        setValidating(false);
    };

    const onChangeUrl = async (newUrl: string, validate = true) => {
        if (validationTimeout.current) {
            clearTimeout(validationTimeout.current);
        }

        const newUrlWithProtocol = HAS_PROTOCOL_PATTERN.test(newUrl) ? newUrl : `https://${newUrl}`;

        setSuggestion(undefined);
        setWarning(undefined);
        setValidating(false);

        setUrl(newUrl);
        if (isUrl(newUrlWithProtocol)) {
            if (validate) {
                queueValidateUrl(newUrlWithProtocol);
            }

            const content: UrlContent = {
                type: DeviceContentType.URL,
                url: newUrlWithProtocol,
            };
            onSelect(content);
        } else if (selected) {
            onSelect(undefined);
        }
    };

    return (
        <TabScreen label="URL" tabTestId="url-tab">
            <Content>
                <p>Enter a URL below to display</p>
                <Field placeholder="Enter URL">
                    <Input
                        type="url"
                        formNoValidate={true}
                        value={url}
                        data-testid="url-input"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            onChangeUrl(event.target.value)
                        }
                    />
                </Field>
                {validating && (
                    <Notice data-testid="notice-validating">
                        Validating URL...
                    </Notice>
                )}
                {notice && !validating && !warning && (
                    <Notice data-testid="notice-ok">{notice}</Notice>
                )}
                {warning && !validating && (
                    <Warning data-testid={`warning-${warning.type}`}>
                        {warning.message}
                    </Warning>
                )}
                {suggestion && (
                    <Notice data-testid="notice-suggestion">
                        Did you mean{' '}
                        <Suggestion
                            data-testid="suggestion"
                            onClick={event => {
                                event.preventDefault();
                                onChangeUrl(suggestion, false);
                            }}
                            href="#"
                        >
                            {suggestion}
                        </Suggestion>
                        ?<br />
                        (Click to correct)
                    </Notice>
                )}
            </Content>
        </TabScreen>
    );
};

const Content = styled(BaseContent)`
    @media (min-width: 640px) {
        justify-content: flex-start;
    }
`;

const Notice = styled.p`
    color: var(--notice);
`;

const Warning = styled.p`
    color: var(--warning);
`;

const Suggestion = styled.a`
    color: var(--foreground);
    text-decoration: underline;
`;

export default UrlTabScreen;
