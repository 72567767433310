import {Trans} from 'react-i18next';
import {from} from 'rxjs';
import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';

import {useBluetooth} from '../../services/bluetooth';
import Button from '../../components/Button';
import Content from '../../components/Content';
import helmet from '../SelectNetwork/helmet.svg';

interface Props {
    onEnrolled: () => void;
    onUnenrolled: () => void;
}

const ConnectedToNetwork: React.FC<Props> = ({onEnrolled, onUnenrolled}) => {
    const {loadIdentifiers, network} = useBluetooth();
    const [isEnrolled, setIsEnrolled] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        const identifiers$ = from(loadIdentifiers());
        const subscription = identifiers$.subscribe(identifiers => {
            setIsEnrolled(Boolean(identifiers.accountId));
            setLoading(false);
        });

        return () => subscription.unsubscribe();
    }, []);

    const onNext = useMemo(() => {
        if (isEnrolled === undefined) return;
        return isEnrolled ? onEnrolled : onUnenrolled;
    }, [isEnrolled]);

    return (
        <Wrap data-testid={'connected-to-network'}>
            <img src={helmet} />
            <h1>
                <Trans i18nKey="connectedToNetwork.title" name={network.ssid}>
                    Device {loading ? 'is connecting' : 'now connected'} to <small>&lsquo;{network.ssid}&rsquo;</small>
                </Trans>
            </h1>
            <Button testId="next-button" onClick={loading ? undefined : onNext}>
                <Trans i18nKey="connectedToNetwork.continueButton">{loading ? 'Loading...' : 'Continue'}</Trans>
            </Button>
        </Wrap>
    );
};

export default ConnectedToNetwork;

const Wrap = styled(Content)`
    flex: 1 0 auto;

    h1 {
        text-align: center;

        small {
            text-transform: none;
            font-size: 0.75em;
            margin-top: 0.5rem;
        }
    }

    img {
        flex: 0 0 auto;
        margin: 0 0.5rem 1rem;
        height: auto;
        width: 8rem;
    }
`;
