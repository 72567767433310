import {Trans} from 'react-i18next';
import React from 'react';
import styled from 'styled-components';

import {Translation} from '../../types/language';
import Content from '../../components/Content';
import screens from './screens.svg';

interface Props {
    pairingStatus: Translation | undefined | null;
}

const Pairing: React.FC<Props> = ({pairingStatus}) => (
    <Wrap data-testid="pairing">
        <p>
            <Trans i18nKey="pairing.title">Securely connecting to meld device...</Trans>
        </p>
        <img src={screens} />

        {Boolean(pairingStatus) && (
            <p>
                <small>
                    <Trans i18nKey={pairingStatus.key}>{pairingStatus.fallback}</Trans>
                </small>
            </p>
        )}
    </Wrap>
);

export default Pairing;

const Wrap = styled(Content)`
    img {
        flex: 0 1 auto;
        margin: 0 -2rem 1rem;
        max-width: 80vw;
    }

    p {
        font-size: 1.5rem;
        margin-bottom: 3rem;
        max-width: 10em;

        small {
            color: var(--success);
            font-size: 1rem;
        }
    }
`;
