export enum VersionChannel {
    BETA = 'beta',
    DEVELOPMENT = 'development',
    STABLE = 'stable',
}

export enum VersionStatus {
    PUBLISHED = 'published',
    PENDING = 'pending',
    DEPRECATED = 'deprecated',
}

export interface BaseDraftVersion {
    version: string;
    channel: VersionChannel;
    status: VersionStatus;
}

export interface DraftURLVersion extends BaseDraftVersion {
    url: string;
}

export type DraftVersion = DraftURLVersion;

export interface Version {
    id: number;
    applicationId: number;
    version: string;
    channel: VersionChannel;
    status: VersionStatus;
    properties?: {
        url?: string;
    }
}
