import {BehaviorSubject, Observable} from 'rxjs';
import {flatMap, startWith} from 'rxjs/operators';

import {BluetoothInterface, BluetoothRequest} from '../types/bluetooth';
import * as bluetoothRequests from './bluetoothRequests';
import delay from '../helpers/delay';

export const BLUETOOTH_RESPONSE_TIMEOUT_MESSAGE = 'Device took too long to respond';
export const BLUETOOTH_RESPONSE_WAIT_TIME = process.env.BLUETOOTH_RESPONSE_WAIT_TIME
    ? parseInt(process.env.BLUETOOTH_RESPONSE_WAIT_TIME, 10)
    : 10000;

export const BLUETOOTH_REQUEST_TIMEOUT_MESSAGE = 'Bluetooth request timed out';
export const BLUETOOTH_REQUEST_WAIT_TIME = process.env.BLUETOOTH_REQUEST_WAIT_TIME
    ? parseInt(process.env.BLUETOOTH_REQUEST_WAIT_TIME, 10)
    : 5000;

export const MELD_READ_CHARACTERISTIC_ID = '00002aba-0000-1000-8000-00805f9b34fb';
export const MELD_SERVICE_UUID = '00001825-0000-1000-8000-00805f9b34fb';
export const MELD_WRITE_CHARACTERISTIC_ID = '00002abb-0000-1000-8000-00805f9b34fb';

const VALID_TIMES_BETWEEN_MESSAGES = [0, 25, 50, 100, 200, 300];
let timeBetweenMessages = VALID_TIMES_BETWEEN_MESSAGES[0];

export const START_MARKER = '|||ECE25400-BAF8-4AA2|||';
export const END_MARKER = '|||819A-D9EA793991E0|||';
const MAX_TRANSMISSION_SIZE = 20;

export const authenticateDevice = async (
    deviceInterface: BluetoothInterface,
    authenticate: (challenge: string, deviceId: number) => Promise<string>
) => {
    const {challenge, deviceId} = await bluetoothRequests.getAuthenticationChallenge(
        deviceInterface
    );
    const response = await authenticate(challenge, deviceId);
    const authenticated = await bluetoothRequests.authenticate(deviceInterface, response);
    if (!authenticated) {
        throw new Error('Device could not be authenticated');
    }

    return true;
};

export const confirmProtocolVersion = async (
    deviceInterface: BluetoothInterface,
    supportedProtocols: number[]
) => {
    const protocolVersion = await bluetoothRequests.getProtocolVersion(deviceInterface);
    if (!supportedProtocols.includes(protocolVersion)) {
        throw new Error('Bluetooth device incompatible');
    }
};

export const getBluetoothAvailability = () => {
    const availability$ = new BehaviorSubject<boolean>(false);
    if (!window.navigator.bluetooth) {
        return availability$;
    }

    // Listen for updates
    const update$ = new Observable(subscriber => {
        window.navigator.permissions
            .query({name: 'bluetooth'})
            .then(bluetoothStatus => {
                bluetoothStatus.onchange = value => subscriber.next(value);
            })
            .catch(error => subscriber.error(error));
    });

    // Turn updates into availability changes
    update$
        .pipe(
            startWith(false),
            flatMap(() => window.navigator.bluetooth.getAvailability())
        )
        .subscribe({
            next: state => availability$.next(state),
            error: () => availability$.next(true),
        });

    return availability$;
};

export const getMeldDevice = (
    bluetoothApi: Pick<Bluetooth, 'requestDevice'> = window.navigator.bluetooth
) =>
    bluetoothApi.requestDevice({
        filters: [{services: [MELD_SERVICE_UUID]}],
    });

export const send = <T>(
    request: BluetoothRequest<T>,
    inputCharacteristic: BluetoothRemoteGATTCharacteristic
) => {
    const encoder = new TextEncoder();
    const sendFullMessage = async () => {
        const messageContent = START_MARKER + JSON.stringify(request) + END_MARKER;

        const buffer = encoder.encode(messageContent).buffer;
        for (let start = 0; start < buffer.byteLength; start += MAX_TRANSMISSION_SIZE) {
            await sendPacket(
                inputCharacteristic,
                buffer.slice(start, start + MAX_TRANSMISSION_SIZE)
            );
        }
    };
    return sendFullMessage();
};

export const sendPacket = async (
    inputCharacteristic: BluetoothRemoteGATTCharacteristic,
    message: ArrayBuffer
) => {
    while (timeBetweenMessages !== undefined) {
        await delay(timeBetweenMessages);
        try {
            await Promise.race([
                inputCharacteristic.writeValue(message),
                delay(BLUETOOTH_REQUEST_WAIT_TIME).then(() => {
                    throw new Error(BLUETOOTH_REQUEST_TIMEOUT_MESSAGE);
                }),
            ]);
            return;
        } catch (error) {
            if (error.name === 'NotSupportedError') {
                timeBetweenMessages =
                    VALID_TIMES_BETWEEN_MESSAGES[
                        VALID_TIMES_BETWEEN_MESSAGES.indexOf(timeBetweenMessages) + 1
                    ];
            } else {
                throw error;
            }
        }
    }

    throw new Error('Device not responsive');
};
