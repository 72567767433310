import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

interface Props {
    strength: number;
}

const Signal: React.FC<Props> = ({ strength }) => (
    <Wrap>
        {[1, 2, 3, 4].map(bar => (
            <Bar
                key={bar}
                className={classnames({ active: bar <= strength })}
            />
        ))}
    </Wrap>
);

export default Signal;

const Bar = styled.div`
    background: var(--tint-2);
    border-radius: 0.09375rem;
    height: 100%;
    width: 0.1875rem;
    margin-left: 0.1875rem;

    &.active {
        background: var(--tertiary);
    }

    :nth-child(1) {
        height: 50%;
        margin-left: 0;
    }
    :nth-child(2) {
        height: 66.7%;
    }
    :nth-child(3) {
        height: 83.3%;
    }
`;

const Wrap = styled.div`
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    height: 1.25rem;
`;

export const BarWrap = Bar;

export const SignalWrap = Wrap;
