import {Trans} from 'react-i18next';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import {Account} from '../../types/account';
import {useAccounts} from '../../hooks/useAccounts';
import {useBluetooth} from '../../services/bluetooth';
import {useMeld} from '../../services/meld';
import {useReplay} from '../../services/replay';
import Button, {ButtonWrap} from '../../components/Button';
import Content from '../../components/Content';
import Enrolled from '../Enrolled';
import Item from '../../components/List/Item';
import List from '../../components/List';
import cloud from './cloud.svg';

interface Props {
    onEnrolled: () => void;
}

enum Status {
    PENDING,
    ENROLLING,
    ENROLLED,
    FAILED,
}

const KNOWN_ENROLMENT_ERRORS: Record<string, string> = {
    'Credentials invalid': 'Device has already been enrolled in an account',
};

const Enrol: React.FC<Props> = ({onEnrolled}) => {
    const accounts = useAccounts();
    const {reset, identifiers} = useBluetooth();
    const {enrolDeviceAndSwitchAccount} = useMeld();
    const {rememberAccountId} = useReplay();

    const [selectedAccount, setSelectedAccount] = useState<Account>();
    const preselectedAccount = useMemo(() => (accounts?.length === 1 ? accounts[0] : undefined), [
        accounts,
    ]);
    const [enrolledAccount, setEnrolledAccount] = useState<Account>();
    const [status, setStatus] = useState<Status>(Status.PENDING);
    const [notice, setNotice] = useState('');

    const handleCancel = useCallback(() => reset(), [reset]);

    const enrol = async (account: Account) => {
        setStatus(Status.ENROLLING);

        try {
            const {serial} = identifiers;
            await enrolDeviceAndSwitchAccount(serial, account.id);
            rememberAccountId(account.id);
            setEnrolledAccount(account);
            setStatus(Status.ENROLLED);
        } catch (error) {
            const notice =
                error.message in KNOWN_ENROLMENT_ERRORS
                    ? KNOWN_ENROLMENT_ERRORS[error.message]
                    : error.message;

            setNotice(notice);
            setStatus(Status.FAILED);
            throw error;
        }
    };

    const enrolSelected = useCallback(() => {
        if (selectedAccount) enrol(selectedAccount);
    }, [selectedAccount]);

    useEffect(() => {
        if (preselectedAccount) {
            enrol(preselectedAccount);
        }
    }, [preselectedAccount]);

    if (status === Status.ENROLLED) {
        return <Enrolled account={enrolledAccount} onNext={onEnrolled} />;
    }

    if (status === Status.ENROLLING && preselectedAccount) {
        return (
            <Wrap data-testid="enrol">
                <Hero src={cloud} className="large" />
                <h1>
                    <Trans i18nKey="enrol.title">Adding device into account...</Trans>
                </h1>
            </Wrap>
        );
    }

    return (
        <Wrap data-testid="enrol">
            <ScrollArea>
                <Hero src={cloud} />
                <h1>
                    <Trans i18nKey="enrol.title">
                        <small>Step 4:</small>
                        Add device into account
                    </Trans>
                </h1>

                {notice && <Notice data-testid="notice">{notice}</Notice>}

                <AccountList>
                    {accounts?.map((account: Account) => (
                        <AccountItem
                            className={classnames({
                                active: selectedAccount?.id === account.id,
                                disabled: status === Status.ENROLLING,
                            })}
                            data-testid="account-option"
                            key={account.id}
                            onClick={() => setSelectedAccount(account)}
                        >
                            <img src={account.icon} />
                            <span>{account.name}</span>
                        </AccountItem>
                    ))}
                    {!accounts && <p>Loading accounts...</p>}
                </AccountList>
            </ScrollArea>

            <Buttons>
                <Button
                    background="var(--secondary)"
                    color="var(--foreground)"
                    onClick={status !== Status.ENROLLING ? handleCancel : undefined}
                    testId="cancel-button"
                >
                    <Trans i18nKey="enrol.cancelButton">Cancel</Trans>
                </Button>
                <Button
                    testId="enrol-button"
                    onClick={
                        selectedAccount && status !== Status.ENROLLING ? enrolSelected : undefined
                    }
                >
                    {status === Status.ENROLLING && (
                        <Trans i18nKey="enrol.enrollingButton">Adding...</Trans>
                    )}
                    {status !== Status.ENROLLING && <Trans i18nKey="enrol.enrolButton">Add</Trans>}
                </Button>
            </Buttons>
        </Wrap>
    );
};

export default Enrol;

const AccountList = styled(List)`
    text-align: left;
    flex: 1 0;
    width: 100%;
`;

const AccountItem = styled(Item)`
    cursor: pointer;
    user-select: none;

    &.disabled {
        cursor: default;
        opacity: 0.5;
    }

    img {
        border: 0 none;
        flex: 0 0 auto;
        height: 2rem;
        margin-right: 1rem;
        width: 2rem;
        border-radius: 0.5rem;
    }
`;

const Buttons = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;

    ${ButtonWrap} {
        min-width: 0;

        + ${ButtonWrap} {
            margin-left: 1rem;
        }
    }
`;

const Hero = styled.img`
    height: 4rem;

    &.large {
        height: auto;
    }
`;

const Notice = styled.p`
    color: var(--warning);
`;

const ScrollArea = styled.div`
    flex: 1 1;
    overflow: auto;
    margin: -1rem -2rem 1rem;
    padding: 1rem 2rem 0;
`;

const Wrap = styled(Content)`
    h1 {
        margin-bottom: 1.5rem;
    }
`;
