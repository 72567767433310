import { Trans } from 'react-i18next';
import React, { useState } from 'react';

import Button from '../../components/Button';
import Popup from '../../components/Popup';

interface Props {
    onClose: () => void;
}

const BluetoothLostPopup: React.FC<Props> = ({ onClose }) => {
    const [close, setClose] = useState(false);
    return (
        <Popup testId="bluetooth-lost-popup" onClose={onClose} shouldClose={close}>
            <h2>
                <Trans i18nKey="popup.bluetoothLost.title">
                    Device connection lost
                </Trans>
            </h2>
            <p>
                <Trans i18nKey="popup.bluetoothLost.instruction">
                    The connection to your meld device has been lost. Ensure the
                    device is powered on and try connecting again.
                </Trans>
            </p>
            <Button
                background="var(--tertiary"
                color="var(--foreground)"
                onClick={() => setClose(true)}
                testId="bluetooth-lost-popup-close"
            >
                <Trans i18nKey="popup.bluetoothLost.closeButton">Close</Trans>
            </Button>
        </Popup>
    );
};

export default BluetoothLostPopup;
