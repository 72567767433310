import {useRef, useState} from 'react';

import {DeviceContent} from '../types/content';
import {WifiNetwork} from '../types/network';
import {useBluetooth} from '../services/bluetooth';
import {useMeld} from '../services/meld';

export interface ReplayAPI {
    rememberAccountId: (accountId: number) => void;
    rememberContent: (appContent: DeviceContent) => void;
    rememberNetwork: (network: WifiNetwork) => void;
    rememberPassphrase: (passphrase: string) => void;
    reset: () => void;
    replay?: (device: BluetoothDevice) => Promise<void>;
    tasks?: ReplayTask[];
}

interface ReplayInfo {
    network?: WifiNetwork;
    accountId?: number;
    passphrase?: string;
    content?: DeviceContent;
}

export interface ReplayTask {
    label: string;
    run: () => Promise<unknown>;
    status: TaskStatus;
}

export enum TaskStatus {
    DONE = 'done',
    FAILED = 'failed',
    PENDING = 'pending',
    RUNNING = 'running',
}

const useReplay = () => {
    const {loadDevice, getDeviceIdentifiers, joinWifiNetwork} = useBluetooth();
    const {enrolDeviceAndSwitchAccount, loadContent} = useMeld();

    const connectToDevice = useRef<() => Promise<void>>();
    connectToDevice.current = loadDevice;

    const connectToInternet = useRef<() => Promise<unknown>>();
    connectToInternet.current = () => joinWifiNetwork(info.network, info.passphrase);

    const enrolDevice = useRef<() => Promise<void>>();
    enrolDevice.current = async () => {
        const {serial} = await getDeviceIdentifiers();
        await enrolDeviceAndSwitchAccount(serial, info.accountId);
    };

    const addContent = useRef<() => Promise<void>>();
    addContent.current = async () => {
        const {deviceId} = await getDeviceIdentifiers();
        await loadContent(deviceId, info.content);
    };

    const [tasks, setTasks] = useState<ReplayTask[]>([
        {
            label: 'Securely connect to device',
            status: TaskStatus.PENDING,
            run: () => connectToDevice.current(),
        },
        {
            label: 'Connect device to internet',
            status: TaskStatus.PENDING,
            run: () => connectToInternet.current(),
        },
        {
            label: 'Add device to account',
            status: TaskStatus.PENDING,
            run: () => enrolDevice.current(),
        },
        {
            label: 'Load content onto device',
            status: TaskStatus.PENDING,
            run: () => addContent.current(),
        },
    ]);

    const [info, setInfo] = useState<ReplayInfo>({});
    const latestInfo = useRef<ReplayInfo>(info);

    const missingReplayInfo = [
        !(info.network || info.passphrase) && 'Network',
        !info.accountId && 'Account',
        !info.content && 'Content',
    ].filter(part => !!part);

    const noReplay = () => {
        throw new Error(`Missing replay info: ${missingReplayInfo.join(', ')}`);
    };

    const replay = async () => {
        // Reset all tasks
        tasks.map(task => {
            task.status = TaskStatus.PENDING;
        });
        setTasks([...tasks]);

        for (const task of tasks) {
            const updateStatus = (newStatus: TaskStatus) => {
                task.status = newStatus;
                setTasks([...tasks]);
            };

            updateStatus(TaskStatus.RUNNING);

            try {
                await task.run();
                updateStatus(TaskStatus.DONE);
            } catch (e) {
                updateStatus(TaskStatus.FAILED);
                throw e;
                break;
            }
        }
    };

    const api: ReplayAPI = {
        rememberAccountId: (accountId: number) => {
            latestInfo.current = {...latestInfo.current, accountId};
            setInfo(latestInfo.current);
        },
        rememberContent: (content: DeviceContent) => {
            latestInfo.current = {...latestInfo.current, content};
            setInfo(latestInfo.current);
        },
        rememberNetwork: (network: WifiNetwork) => {
            latestInfo.current = {...latestInfo.current, network};
            setInfo(latestInfo.current);
        },
        rememberPassphrase: (passphrase: string) => {
            latestInfo.current = {...latestInfo.current, passphrase};
            setInfo(latestInfo.current);
        },
        replay: missingReplayInfo.length ? noReplay : replay,
        reset: () => setInfo({}),
        tasks,
    };

    return api;
};

export default useReplay;
